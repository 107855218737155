﻿import { defineStore } from 'pinia';

export const useNewsStore = defineStore('news', {
    state: () => ({
        newsPageIndex: 0,
        newsListItems: [],
        newsListTitle: null,
        newsListEnd: false,
        bannerSlugSet: [],
        newsRowData: {},
        newsSliderFeed: [],
        gtmNewsTags: []
    }),
    persist: true,

    getters: {
        /**
         * @returns {Object[]} The slugs of all items displayed in the banner
         */
        newsBannerSlugs: (state) => state.bannerSlugSet,

        /**
         * @returns {Number} the current page index on the `AllNews` template 
         */
        pageIndex: (state) => state.newsPageIndex,

        /**
         * @returns {Object[]} the list of news items to render on the `AllNews` template
         */
        allNewsItems: (state) => state.newsListItems,

        /**
         * @returns {String} the title to display on the `AllNews` template
         */
        allNewsTitle: (state) => state.newsListTitle ? state.newsListTitle : 'News',

        /**
         * @returns {Boolean} have all the news items been fetched from the API on the `AllNews` template
         */
        allNewsEnd: (state) => state.newsListEnd,

        /**
         * @returns {Object} The news row data for a given id
         */
        getNewsRowDataById: (state) => (id) => state.newsRowData[id],

        /**
         * Get the news slider data feed
         * @returns {Object[]}
         */
        newsSliderData: (state) => state.newsSliderFeed || [],
    },

    actions: {
        /**
         * Reset the state to its initial values
         */
        reset() {
            this.$reset();
        },
        /**
         * Reset specific state properties to their initial values (Lite reset)
         */
        resetNewsListLite() {
            this.newsListItems = [];
            this.newsListTitle = null;
            this.newsListEnd = false;
            this.newsPageIndex = 0;  // Assuming the initial value is 0
        },
        /**
         * Update the page index
         * @param {Number} value The new page index
         */
        setNewsPageIndex(value) {
            this.newsPageIndex = value;
        },
        /**
         * Update the list of news items
         * @param {Object[]} value The new list of news items
         */
        setNewsListItems(value) {
            this.newsListItems = value;
        },
        /**
         * Update the title of the news list
         * @param {String} value The new title
         */
        setNewsListTitle(value) {
            this.newsListTitle = value;
        },
        /**
         * Set whether all news items have been fetched
         * @param {Boolean} value The new value indicating if all items have been fetched
         */
        setNewsListEnd(value) {
            this.newsListEnd = value;
        },
        /**
         * Save the slugs of banner items
         * @param {Object[]} value The new slugs of banner items
         */
        setNewsBannerSlug(value) {
            this.bannerSlugSet = value;
        },
        /**
         * Save news row data for a given id
         * @param {Object} payload The payload containing id and data
         * @param {String} payload.id The id of the news row
         * @param {Object} payload.data The data of the news row
         */
        setNewsRowData({ id, data }) {
            this.newsRowData[id] = data;
        },
        /**
         * Reset the news row data
         */
        resetNewsRowData() {
            this.newsRowData = {};
        },
        /**
         * Save the news slider data feed
         * @param {Object[]} feed The new news slider feed
         */
        setNewsSliderData(feed) {
            this.newsSliderFeed = feed;
        },
        /**
         * Save the news tags for GA filtering
         * @param {Object[]} value The new news tags
         */
        setGtmNewsTags(value) {
            this.gtmNewsTags = value;
        },

    }

});

