﻿
/**
 * Getters for the identity module
 */
export const IDENTITY = {
    profile: 'profile',
    authenticated: 'authenticated',
    staff: 'staff',
    student: 'student',
    staffStudent: 'staffStudent',
    impersonating: 'impersonating',
    siteMode: 'siteMode',
    status: 'status',
    pga: 'pga',
    pgaOnly: 'pgaOnly',
    distance: 'distance',
    SITE_ACTION_DATA_GET: 'SITE_ACTION_DATA_GET',
    DESIGN_THEME: 'IDENTITY_DESIGN_THEME'
};



/**
 * Preferences related getters 
 */
export const PREFERENCES = {
    modulePrefs: 'modulePrefs',
    modulePrefsStatus: 'modulePrefsStatus',
    travelSectionsPrefs: 'travelSectionsPrefs',
    travelSectionsPrefsStatus: 'travelSectionsPrefsStatus',
    jobSectionsPrefs: 'jobSectionsPrefs',
    jobSectionsPrefsStatus: 'jobSectionsPrefsStatus',
    userLinksPrefs: 'userLinksPrefs',
    userLinksPrefsStatus: 'userLinksPrefsStatus',
    GENERAL_DATA: 'generalData'
};

