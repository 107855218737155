﻿import Fetch from '@api/fetch';
import { PREFERENCE_KEYS, PREFERENCE_KEY_REGEX } from '@config/constants';

/**
 * Get the preference sections for the user
 * @returns {Promise}
 */
export function getPreferenceSections() {
    return Fetch.internal.get('/api/preferences/sections');
}

/**
 * Get the preference sections for the user
 * @returns {Promise}
 */
export function getPreferenceDefaultPageOptions() {
    return Fetch.internal.get('/api/preferences/defaultpages');
}

/**
 * Get the deafult page preference
 * @returns {Promise}
 */
export function getPreferenceDefaultPage() {
    return Fetch.internal.get('/api/preferences/default_tab');
}

/**
 * Set the default page preference
 * @returns {Promise}
 */
export function updatePreferenceDefaultPage(value) {
    return Fetch.internal.put(`/api/preferences/default_tab?value=${value}`);
}

/**
 * Set the default site preference
 * @returns {Promise}
 */
export function updatePreferenceDefaultSite(value) {
    return Fetch.internal.put(`/api/preferences/default_site?value=${value}`);
}

/**
 * Get the module preferences
 * @returns {Promise}
 */
export function getModulePreferences() {
    return Fetch.internal.get('/api/preferences/module');
}

/**
 * Set the module preferences
 * @returns {Promise}
 */
export function updateModulePreferences(preferences) {
    return Fetch.internal.put('/api/preferences/module', preferences);
}

/**
 * Get a user's preference data from a news endpoint
 * @param {String} endpoint The endpoint guid
 * @return {Promise<Object[]>}
 */
export function getNewsPreferences(endpoint) {
    return Fetch.internal.get(`/api/preferences/news/${endpoint}`);
}

/**
 * Update a users preferences for a news endpoint
 * @param {String} endpoint The endpoint guid
 * @param {String[]} channelIds The list of channel ids to opt out of
 */
export function updateNewsPreferences(endpoint, channelIds) {
    return Fetch.internal.put(`/api/preferences/news/${endpoint}`, channelIds);
}

/**
 * Get the travel sections preferences
 * @returns {Promise}
 */
export function getTravelSectionsPreference() {
    return Fetch.internal.get('/api/preferences/travel_sections');
}

/**
 * Get the job sections preferences
 * @returns {Promise}
 */
export function getJobSectionsPreference() {
    return Fetch.internal.get('/api/preferences/job_sections');
}

/**
 * Set the travel sections preferences
 * @returns {Promise}
 */
export function updateTravelSectionsPreference(value) {
    return Fetch.internal.put(`/api/preferences/travel_sections?value=${value}`);
}

/**
 * Set the job sections preferences
 * @returns {Promise}
 */
export function updateJobSectionsPreference(value) {
    return Fetch.internal.put(`/api/preferences/job_sections?value=${value}`);
}

/**
 * Generic preference getter
 * @param {String} key
 * @param {String} value
 * @returns {Promise}
 */
export function updatePreference(key, value) {
    if (!isValidKey(key)) {
        return Promise.reject('Not a valid preference key');
    }
    if (value === undefined) {
        return Promise.reject('No preference value given');
    }
    if (value === null) {
        value = '';
    }

    return Fetch.internal.put(`/api/preferences/${key}?value=${value}`);
}

/**
 * Generic preference setter
 * @param {String} key
 * @returns {Promise}
 */
export function getPreference(key) {
    if (!isValidKey(key)) {
        return Promise.reject('Not a valid preference key');
    }

    return Fetch.internal.get(`/api/preferences/${key}`);
}

/**
 * Generic check on preference keys
 * @param {any} key
 */
function isValidKey(key) {
    // exact match on key
    if (Object.values(PREFERENCE_KEYS).some(_key => _key === key)) {
        return true;
    }

    // check the regex
    if (Object.values(PREFERENCE_KEY_REGEX).some(_regex => key.match(_regex))) {
        return true;
    }

    return false;
}

/**
 * Set the Ready For University preference as dismissed
 * @returns {Promise<String>}
 */
export function updateReadyForUniversityPreference() {
    return Fetch.internal.put('/api/preferences/ready_for_university_dismissed?value=true');
}