﻿const UnauthorisedPage = () => import('@pages/error/UnauthorisedPage.vue');
const NotFoundPage = () => import('@pages/error/NotFoundPage.vue');
const MissingResource = () => import('@pages/error/MissingResource.vue');

export const ERROR_ROUTES = {
    UNAUTHORISED: 'unauthorised',
    ERROR: 'error',
    BAD_REQUEST: 'badrequest',
    NO_ACCESS: 'noaccess',
    NOT_FOUND: 'notfound',
    ACCESS_ENDED: 'access-ended'
};

export default [
    {
        path: '/access-ended',
        name: ERROR_ROUTES.ACCESS_ENDED,
        component: () => import('@core-portal/components/pages/identity/AccessEnded.vue'),
        meta: {
            requiresAuth: false,
            title: 'Access Ended'
        }
    },
    {
        path: '/unauthorised',
        component: UnauthorisedPage,
        name: ERROR_ROUTES.UNAUTHORISED,
        meta: {
            title: 'Unauthorised'
        }
    },
    {
        path: '/error',
        beforeEnter() {
            location.href = 'essential-links?mode=error';
        },
        name: ERROR_ROUTES.ERROR,
        meta: {
            title: 'Error'
        }
    },
    {
        path: '/badrequest',
        beforeEnter() {
            location.href = 'essential-links?mode=badrequest';
        },
        name: ERROR_ROUTES.BAD_REQUEST,
        meta: {
            title: 'Bad Request'
        }
    },
    {
        path: '/noaccess',
        beforeEnter() {
            location.href = 'essential-links?mode=noldap';
        },
        name: ERROR_ROUTES.NO_ACCESS,
        meta: {
            title: 'No Access'
        }
    },
    {
        path: '/missing-resource',
        component: MissingResource,
        name: 'missing-resource',
        meta: {
            title: 'Missing Resource',
            requiresAuth: true
        }
    },
    // *** This should always come last!! ***
    {
        path: '/:pathMatch(.*)*',
        name: ERROR_ROUTES.NOT_FOUND,
        component: NotFoundPage,
    }
];