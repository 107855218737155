﻿import * as types from '../mutation-types';
import { IDENTITY } from '../getters';
import { DESIGN_THEME_DISPATCH } from '../action-types';
import { PREFERENCE_KEYS, REQUEST_STATUS, SITEMODE, SITESTYLE } from '@config/constants';
import { getSiteModeFromPath } from '@utilities/SiteHelper.js';
import { resetState } from '../StoreHelpers';
import { getOsColourPreference } from '@core-portal/scripts/colourTheme.helper';
import { updatePreference } from '@api/preferences';

const osTheme = getOsColourPreference(); // set the colour preference from the OS

const initialState = () => ({
    profile: {
        designTheme: null,
        isAuthenticated: false,
        isStaff: false,
        isStudent: false,
        isImpersonating: false,
        roles: [],
        siteActions: {
            tokenExpired: false
        },
        siteStyle: SITESTYLE.LANCASTER     
    },
    status: null
});

const mutations = {
    /**
     * Reset the state
     * @param {any} state
     */
    [types.IDENTITY_RESET](state) {
        resetState(state, initialState());
    },

    /**
     * Successfully fetched the profile object
     * @param {Object} profile
     */
    [types.PROFILE_SUCCESS](state, profile) {
        state.status = REQUEST_STATUS.SUCCESS;
        state.profile = profile;
    },

    /**
     * Add flag for any site action key to the state
     * @param {any} state
     * @param {Object} value
     * @param {String} value.key The site action key
     * @param {Object} value.flag The site action flag (true/false/null)
     */
    [types.SITE_ACTION_DATA_SET](state, value) {
        state.profile.siteActions[value.key] = value.flag;
    },

    [types.DESIGN_THEME](state, theme) {
        state.profile.designTheme = theme;
    }
};

const getters = {
    // the profile object (with sensitive info hidden)
    [IDENTITY.profile]: state => state.profile,

    // is the user currently authenticated
    [IDENTITY.authenticated]: state => state.profile && state.profile.isAuthenticated,

    // is the user a staff member
    [IDENTITY.staff]: state => state.profile && state.profile.isStaff,

    // is the user a student
    [IDENTITY.student]: state => state.profile && state.profile.isStudent,

    // is the user both a staff member and also a student
    [IDENTITY.staffStudent]: state => state.profile && state.profile.isStaff && state.profile.isStudent,

    // is the user currently being impersonated?
    [IDENTITY.impersonating]: state => state.profile && state.profile.isImpersonating,

    [IDENTITY.siteMode]: state => {
        const profile = state.profile;

        if (!profile || !profile.isAuthenticated) {
            return getSiteModeFromPath();
        } else if (profile.isStaff && profile.isStudent) {
            return SITEMODE.BOTH;
        } else if (profile.isStaff) {
            return SITEMODE.INTRANET;
        } else {
            // covers students and PGAs
            return SITEMODE.PORTAL;
        }
    },

    // get the status of the profile API call
    [IDENTITY.status]: state => state.status,

    // is the user a PGA?
    [IDENTITY.pga]: state => state.profile && state.profile.roles.includes('pga'),
    [IDENTITY.pgaOnly]: state => state.profile &&
        state.profile.roles.includes('pga') &&
        !state.profile.isStaff &&
        !state.profile.isStudent,

    // is the user a Distance Learner?
    [IDENTITY.distance]: state => state.profile && state.profile.roles.includes('distance'),

    /**
     * Retrieve site action data by key
     * @param {String} key The site action key to get data for
     * @returns {Object} The site action flag (e.g. true/false/null)
     */
    [IDENTITY.SITE_ACTION_DATA_GET]: state => key => {
        return state.profile.siteActions[key];
    },

    [IDENTITY.DESIGN_THEME]: state => state.profile?.designTheme ?? osTheme
};

const actions = {
    [DESIGN_THEME_DISPATCH]({ commit, getters }, theme) {
        // allow switching theme when unauthenticated but don't save the preference - mobile mega menu point in case
        commit(IDENTITY.DESIGN_THEME, theme);
        if (getters[IDENTITY.authenticated]) {
            // save in the database - pass in `null` to reset
            updatePreference(PREFERENCE_KEYS.DESIGN_THEME, theme)
                .catch(() => { /* silently fail */ });
        }
    }
};

export default {
    state: initialState(),
    mutations,
    getters,
    actions
};