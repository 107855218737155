﻿
/**
 * JWT Token
 */
export const IDENTITY_RESET = 'IDENTITY_RESET';

/**
 * Profile
 */
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const DESIGN_THEME = 'IDENTITY_DESIGN_THEME';

/**
 * Profile - Site Actions
 */
export const SITE_ACTION_DATA_SET = 'SITE_ACTION_DATA_SET';



/**
 * Preferences
 */
export const PREFERENCES_REQUEST = 'PREFERENCES_REQUEST';
export const PREFERENCES_FAILURE = 'PREFERENCES_FAILURE';
export const PREFERENCES_SUCCESS = 'PREFERENCES_SUCCESS';
export const PREFERENCES_RESET = 'PREFERENCES_RESET';

export const PREFERENCES_GENERAL_DATA_SET = 'PREFERENCES_GENERAL_DATA_SET';

export const MODULE_PREFERENCES = 'MODULE_PREFERENCES';
export const TRAVELSECTIONS_PREFERENCES = 'TRAVELSECTIONS_PREFERENCES';
export const JOBSECTIONS_PREFERENCES = 'JOBSECTIONS_PREFERENCES';
export const USERLINKS_PREFERENCES = 'USERLINKS_PREFERENCES';

