﻿<template>
    <span v-if="false" />
</template>
<script>

// VIEWPORT sizes available
export const VIEWPORT = {
    XXXS: 320,
    XXS_400: 400,
    XXS: 480,
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1300
};

export default {
    props: {
        sizes: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    emits: ['change'],
    data: () => ({
        listeners: []
    }),
    beforeMount() {
        // if no sizes were provided add the standard ones
        if (this.sizes.length === 0) {
            this.listeners.push(this.createListenerRecord(VIEWPORT.XXS));
            this.listeners.push(this.createListenerRecord(VIEWPORT.XS));
            this.listeners.push(this.createListenerRecord(VIEWPORT.SM));
            this.listeners.push(this.createListenerRecord(VIEWPORT.MD));
        } else {
            // add the selected sizes to the listeners array (sort smallest first)
            let sizesCopy = [...this.sizes];
            sizesCopy
                .sort((a, b) => {
                    return b < a ? 1 : -1;
                })
                .forEach(x => {
                    this.listeners.push(this.createListenerRecord(x));
                });
        }
        // create the viewport and listener
        this.listeners.forEach(x => {
            x.viewport = this.createViewPort(x.size);
            if (x.viewport) {
                try {
                    x.viewport.addEventListener('change', this.switchViewEvent);
                } catch (e) {
                    /*
                     * Bug fallback: MediaQueryList does not inherit from EventTarget Safari < 14.
                     * See https://caniuse.com/?search=matchMedia#bugs and
                     * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#browser_compatibility
                     */
                    x.viewport.addListener(this.switchViewEvent);
                }
            }
        });
        // check the current view
        this.switchViewEvent();
    },
    beforeUnmount() {
        this.listeners.forEach(x => {
            if (x.viewport) {
                try {
                    x.viewport.removeEventListener('change', this.switchViewEvent);
                } catch (e) {
                    /*
                     * Bug fallback: MediaQueryList does not inherit from EventTarget Safari < 14.
                     * See https://caniuse.com/?search=matchMedia#bugs and
                     * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#browser_compatibility
                     */
                    x.viewport.removeListener(this.switchViewEvent);
                }
            }
        });
    },
    methods: {
        /**
         * Create the listener record for the given viewport size
         * @param viewportSize The size to create a record for
         */
        createListenerRecord(viewportSize) {
            let listenerRecord = {};
            listenerRecord.size = viewportSize;
            listenerRecord.viewport = null;
            return listenerRecord;
        },
        /**
         * Create the matchMedia entry for the given viewport size
         * @param viewportSize The size to create a record for
         */
        createViewPort(viewportSize) {
            switch (viewportSize) {
            case VIEWPORT.XXXS:
                return window.matchMedia('(max-width: 320px)');
            case VIEWPORT.XXS:
                return window.matchMedia('(max-width: 480px)');
            case VIEWPORT.XS:
                return window.matchMedia('(max-width: 768px)');
            case VIEWPORT.SM:
                return window.matchMedia('(max-width: 992px)');
            case VIEWPORT.MD:
                return window.matchMedia('(max-width: 1200px)');
            case VIEWPORT.LG:
                return window.matchMedia('(min-width: 1201px)');
            default:
                return null;
            }
        },
        /**
         * Event fired when the view changes
         */
        switchViewEvent() {
            // find the viewport that is currently matching and fire an event
            if (this.listeners && this.listeners.length > 0) {
                let currentListener = this.listeners.find(x => x.viewport?.matches);
                if (currentListener) {
                    this.$emit('change', currentListener.size);
                    return;
                }
            }
            // if no listeners are currently matching return a dummy event to show that we don't know the size
            this.$emit('change', 0);
        }
    }
};
</script>