﻿import Fetch from '@api/fetch';

/**
 * Get a user's list of subscribed and unsubscribed widgets
 * @returns {Promise}
 */
export function getUserLinks() {
    return Fetch.internal.get('/api/userlinks');
}

/**
 * Add a list item for the user
 * @param {Object} link The link model to be added
 * @returns {Promise}
 */
export function addUserLink(link) {
    return Fetch.internal.post('/api/userlinks/', link);
}

/**
 * Update a list item for the user
 * @param {Object} link The link model to be updated
 * @returns {Promise}
 */
export function updateUserLink(link) {
    return Fetch.internal.put(`/api/userlinks/${link.id}`, link);
}

/**
 * Update the order of link items
 * @param {Array} links The links in order to be updated
 * @returns {Promise}
 */
export function updateUserLinksOrder(links) {
    return Fetch.internal.put('/api/userlinks/order', { links });
}


/**
 * Delete a list item for the user
 * @param {Number} id The user link id
 * @returns {Promise}
 */
export function deleteUserLink(id) {
    return Fetch.internal.delete(`/api/userlinks/${id}`);
}