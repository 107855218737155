﻿<template>
    <span v-if="false" />
</template>
<script>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { IDENTITY } from '@store/getters';
import { updateColorPreferenceOnDom } from '@core-portal/scripts/colourTheme.helper';
export default {
    setup() {
        const store = useStore();
        const preference = computed(() => store.getters[IDENTITY.DESIGN_THEME]);

        const targetElement = document.body;

        // watch for any changes based on the profile preferences
        watch(preference, newTheme => updateColorPreferenceOnDom(newTheme, targetElement));

        // Apply the preferred theme class to <body> element (after the watcher to avoid race conditions)
        updateColorPreferenceOnDom(preference.value, targetElement);
    }
};
</script>