﻿<template>
    <div v-if="hasActions">
        <!-- Portal Message for 'where students are currently living/residing' during Covid -->
        <WhereStudentsAreLiving
            v-if="showCheckLocation"
            :site-name="siteNameText"
        />

        <!--Notify the user that their token has expired-->
        <TokenExpiredModal
            v-if="showTokenExpired"
            :site-name="siteNameText"
        />

        <GenericSiteActionModal
            v-if="showGenericSiteAction"
            :site-name="siteNameText"
            :site-action="siteAction"
        />
    </div>
</template>

<script>

import { getSiteName } from '@utilities/SiteHelper';
import { SITE_ACTIONS, currentSiteAction, hasSiteActions } from '@utilities/SiteActionHelper';

import GenericSiteActionModal from '@molecules/site-action/GenericSiteActionModal.vue';
import TokenExpiredModal from '@molecules/site-action/TokenExpiredModal.vue';
import WhereStudentsAreLiving from '@molecules/site-action/WhereStudentsAreLiving.vue';

export default {
    components: {
        GenericSiteActionModal,
        TokenExpiredModal,
        WhereStudentsAreLiving
    },
    data() {
        return {
            genericSiteActions: [
                SITE_ACTIONS.CHECK_REGISTRATION,
                SITE_ACTIONS.FULL_ACCOUNT,
                SITE_ACTIONS.ILSP_PREFS,
                SITE_ACTIONS.INTERNATIONAL_CHECKIN,
                SITE_ACTIONS.LOCATION,
                SITE_ACTIONS.MFA_STATUS,
                SITE_ACTIONS.READY_FOR_UNIVERSITY,
                SITE_ACTIONS.REREG_REMINDER,
                SITE_ACTIONS.SECURITY_TRAINING,
                SITE_ACTIONS.STUDENT_TRAINING
            ]
        };
    },
    computed: {
        /**
         * Get the name of the site for use in displaying to the user
         */
        siteNameText() {
            return getSiteName();
        },
        hasActions() {
            return hasSiteActions();
        },
        siteAction() {
            return currentSiteAction(this.$route.path);
        },
        showTokenExpired() {
            return SITE_ACTIONS.TOKEN_EXPIRED === this.siteAction;
        },
        showCheckLocation() {
            return SITE_ACTIONS.CHECK_LOCATION === this.siteAction;
        },
        showGenericSiteAction() {
            return this.genericSiteActions.some(x => x === this.siteAction);
        }
    }
};
</script>