﻿import { defineStore } from 'pinia';

const JWT_EXPIRY = {
    DEFAULT_MINS: 120 // 2 hours -  How long the JWT lasts in minutes (if expiry date is not present)
};

// the token expiry method
let tokenExpiry;

export const useTokenStore = defineStore('token', {
    state: () => ({
        jwt: null,
        jwtExpired: null
    }),
    actions: {
        reset() {
            this.$reset();
            window.clearTimeout(tokenExpiry);
        },
        setJwt(jwt) {
            this.jwt = jwt;
            this.jwtExpired = false;

            const token = decodeToken(this.jwt);

            // reset the token timer for automated log out
            window.clearTimeout(tokenExpiry);

            // Set up the countdown/redirect for when the token expires
            tokenExpiry = setTimeout(async () => {
                this.jwtExpired = true;
            }, jwtTimoutAsMs(token));

        }
    }
});

/**
 * Decode a jwt token
 * @param {String} jwt The jwt to decode
 * @returns {Object} The JSON object for the token data (or null)
 */
function decodeToken(jwt) {
    if (!jwt) {
        return null;
    }
    const base64UrlPayload = jwt.split('.')[1];
    const bas64Payload = base64UrlPayload.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(bas64Payload));
}

/**
 * Get the number of remaining ticks (in milliseconds) before JWT expires
 * @param {Object} token The decoded jwt token
 * @returns {number} the remaining ticks in milliseconds
 */
function jwtTimoutAsMs(token) {

    let ticks = 0;
    if (token) {
        // get the Unix epoch - the token issue time in milliseconds
        if (token.exp) {
            // get the expiry date
            ticks = token.exp * 1000;
        } else {
            // if we don't have the expiry date get the issued at date
            ticks = (token.iat + (JWT_EXPIRY.DEFAULT_MINS * 60)) * 1000;
        }
    }

    // default period as fall back
    if (ticks === 0) {
        return (JWT_EXPIRY.DEFAULT_MINS * 60) * 1000;
    }

    let now = new Date().getTime();
    return ticks - now;
}