﻿<template>
    <form
        class="m-search"
        @submit="doSearch"
    >
        <label
            :for="controlId"
            class="o-screen-reader--hide"
        >
            Search
        </label>
        <div class="m-search__search-box-container">
            <input
                :id="controlId"
                ref="mainsearch"
                v-model="searchValue"
                title="Search Box"
                class="m-search__search-box"
                type="search"
                name="q"
                placeholder="Search..."
                :list="datalistId"
                autocomplete="off"
                @input="fetchSuggestions"
            >
            <datalist :id="datalistId">
                <option
                    v-for="s in suggestions"
                    :key="s"
                >
                    {{ s }}
                </option>
            </datalist>
            <button
                class="o-screen-reader--hide"
                type="submit"
                tabindex="-1"
                @click="doSearch"
            >
                Search
            </button>
            <a
                v-if="searchValue"
                id="clear-search"
                href="javascript:void(0)"
                role="button"
                aria-label="Delete search text"
                class="m-search__search-button m-search__search-button--times"
                tabindex="0"
                @click="deleteSearchText"
                @keyup.enter="deleteSearchText"
            >
                <IconTimes class="m-search__search-icon svg-inline--fa fa-w-16 u-ie-11-hide" />
            </a>
            <a
                id="submit-search"
                href="javascript:void(0)"
                role="button"
                aria-label="Submit search"
                tabindex="0"
                class="m-search__search-button"
                @click="doSearch"
            >
                <IconSearch class="m-search__search-icon svg-inline--fa fa-w-16" />
            </a>
        </div>
    </form>
</template>

<script>
import { IconSearch, IconTimes } from '@cisweb/icon-library-vue3';
import { SEARCH_ROUTES } from '@routes/search';
import { getSearchSuggestions } from '@api/search';


export default {
    components: {
        IconSearch,
        IconTimes
    },
    props: {
        platform: {
            type: String,
            required: false,
            default: () => 'desktop'
        },
        isMobileMenuSearch: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    emits: ['closeMobileDrawer'],
    data() {
        return {
            searchValue: null,
            suggestions: [],
            timeoutFunction: null,
            timeout: 400
        };
    },
    computed: {
        controlId() {
            if (this.isMobileMenuSearch) {
                return `menu-search-box-main-${this.platform.trim()}`;
            } else {
                return `search-box-main-${this.platform.trim()}`;
            }
        },
        datalistId() {
            return `autocomplete-${this.platform.trim()}`;
        }
    },
    methods: {
        /**
         * Do a search, update the route
         * @param {Event} event
         */
        doSearch(event) {
            event.preventDefault();

            this.$router.push({
                name: SEARCH_ROUTES.SEARCH,
                query: {
                    q: this.searchValue
                }
            });
            // close mob menu if open
            this.$emit('closeMobileDrawer');
        },
        deleteSearchText() {
            this.searchValue = null;
            this.focusInput();
        },
        /*
         * Move the 'focus' onto the search input
         */
        focusInput() {
            this.$refs.mainsearch.focus();
        },

        /**
         * Query funnelback for suggestions
         */
        async fetchSuggestions() {
            clearTimeout(this.timeoutFunction);
            this.timeoutFunction = setTimeout(async () => {
                try {
                    if (this.searchValue && this.searchValue.length) {
                        let response = await getSearchSuggestions(this.searchValue);
                        this.suggestions = [...new Set(response)];
                    }
                } catch {
                    //silently fail
                }
            }, this.timeout);
        }
    }
};
</script>