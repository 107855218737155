﻿export const CONDOLENCES_ROUTES = {
    ROOT: 'CONDOLENCES_ROOT',
    PAGE: 'CONDOLENCES_PAGE'
};

export default [
    {
        path: '/condolences',
        component: () => import('@pages/condolences/CondolencesIndex.vue'),
        name: CONDOLENCES_ROUTES.ROOT,
        meta: {
            title: 'Condolences',
        }
    },
    {
        path: '/condolences/:slug',
        component: () => import('@pages/condolences/CondolencesPage.vue'),
        name: CONDOLENCES_ROUTES.PAGE,
        meta: {
            title: 'Condolences',
            subNav: true,
            subNavName: 'Condolence'
        }
    }
];