﻿/**
 * Build query parameters for a url
 * @param {Object} data The key value pairs to build
 * @returns {String}
 */
export function buildQueryParams(data) {
    const params = Object
        .keys(data)
        .map(key => {
            const val = data[key];
            if (val || val === false || (typeof val === 'number' && !isNaN(val))) {
                return `${key}=${encodeURIComponent(val)}`;
            }
            return false;
        })
        .filter(k => k)
        .join('&');

    return params ? `?${params}` : '';
}
