﻿import { SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

export const TRAVEL_ROUTES = {
    HOME: 'travel' // can't change this because of default page preferences
};

export default [
    {
        path: '/travel',
        component: () => import('@pages/travel/TravelPage.vue'),
        name: TRAVEL_ROUTES.HOME,
        meta: {
            requiresAuth: false,
            title: 'Travel',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    }
];