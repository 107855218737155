﻿const HOME_ROUTES = {
    HOME: 'home',
    HOME_EMPTY: 'home_empty',
    HOME_ROOT: 'home_root'
};

/**
 * The default route. Redirect everything that attempts to access the / root level
 * of the app to the news page.
 */
export default [
    {
        path: '',
        name: HOME_ROUTES.HOME_EMPTY,
        redirect: {
            name: HOME_ROUTES.HOME
        }
    },
    {
        path: '/',
        name: HOME_ROUTES.HOME_ROOT,
        redirect: {
            name: HOME_ROUTES.HOME
        }
    },
    {
        path: '/home',
        component: () => import('@pages/entry/HomePage.vue'),
        name: HOME_ROUTES.HOME,
        meta: {
            title: 'Home',
            requiresAuth: true
        }
    }
];