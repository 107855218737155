﻿import Fetch from '@api/fetch';

/**
 * Get the "check location" content
 * @returns {Promise}
 */
export function getCheckLocationContent() {
    return Fetch.internal.get('/api/site-action/check-location');
}

/**
 * Set the "check location" response for the user
 * @returns {Promise}
 */
export function updateCheckLocationResponse(value) {
    return Fetch.internal.put(`/api/site-action/check-location?value=${value}`);
}

/**
 * Get the content for a site action
 * @param {String} siteAction The site action key to get content for
 */
export function getSiteActionContent(siteAction) {
    return Fetch.internal.get(`/api/site-action/${siteAction}`);
}

/**
 * Set a snooze on the site action for the user
 * @param {String} siteAction The site action key to set the snooze for
 */
export function setSiteActionSnooze(siteAction) {
    return Fetch.internal.put(`/api/site-action/${siteAction}/snooze`);
}