﻿<template>
    <article
        ref="notifItem"
        class="a-notification"
    >
        <div class="o-container o-container--notification a-grid-notification">
            <div class="a-notification__date">
                <span>{{ date }}</span>
            </div>
            <div class="a-notification__body">
                <div class="a-notification__header">
                    <h3>{{ notification.title }}</h3>
                </div>
                <i class="a-notification__channel">
                    # {{ notification.channel_name }}
                </i>
                <div v-if="isOversized">
                    <router-link
                        :to="itemRoute"
                        @click="closeDrawerAndSendGa(notification.title, notification.notification_id)"
                    >
                        View full details
                    </router-link>
                </div>
                <div v-else>
                    <div class="a-notification__message">
                        <span
                            v-if="!showViewMore"
                            class="a-notification__message__expanded"
                            :class="extraCss"
                            v-html="notification.message"
                        />
                        <span
                            v-else
                            class="a-notification__message__content"
                            v-html="notification.message"
                        />
                    </div>
                    <div
                        v-if="overflow || externalLink"
                        class="a-notification__message__more"
                    >
                        <button
                            v-if="overflow"
                            class="a-notification__view-more"
                            href="#expand"
                            title="Expand the notification"
                            :tabindex="getIndex"
                            @click.prevent="handleNotificationExpand"
                        >
                            <strong>{{ moreText }}</strong>
                        </button>
                        <span v-if="overflow && externalLink"> | </span>
                        <a
                            v-if="externalLink"
                            :href="externalLink"
                        >
                            {{ moodleChannel ? 'View details in Moodle' : 'Further information' }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="a-notification__button">
                <div class="a-notification__button-wrapper">
                    <GenericButton
                        flavour="notification-dismiss"
                        title="Dismiss this notification"
                        :tabindex="getIndex"
                        class="a-button--notifications a-button--full-width-xxs"
                        :disabled="sending"
                        @click="handleDismiss"
                    >
                        <template #button-text>
                            <span v-if="canDismiss">Dismiss</span>
                            <Check
                                v-if="success"
                                class="svg-inline--fa fa-w-16"
                            />
                            <div
                                v-if="sending"
                                class="u-flex-column"
                                aria-busy="true"
                            >
                                <LoadingSpinner
                                    colour="#fff"
                                    :size="19"
                                />
                            </div>
                        </template>
                    </GenericButton>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import { IconCheck } from '@cisweb/icon-library-vue3';
import GenericButton from '@atoms/buttons/GenericButton.vue';
import LoadingSpinner from '@atoms/animations/LoadingSpinner.vue';

import { formatDate, getLocalDate } from '@utilities/DateHelper';
import { DateTime } from 'luxon';
import { updateNotificationDelivery } from '@api/notifications.api';
import { REQUEST_STATUS, EVENT_NAME } from '@config/constants';
import { NOTIFICATION_ROUTES } from '@routes/notifications';
import { sendCustomEvent } from '@utilities/AnalyticsHelper.js';
import UrlHelper from '@utilities/UrlHelper';

import { computed } from 'vue';
import { useNotificationsStore } from '@stores/notifications.module';

export default {
    name: 'NotificationItem',
    components: {
        Check: IconCheck,
        GenericButton,
        LoadingSpinner
    },
    props: {
        notification: {
            type: Object,
            required: true
        },
        showViewMore: {
            type: Boolean,
            required: false,
            default: () => false
        },
        actionSource: {
            type: String,
            required: true
        }
    },
    emits: ['viewMore', 'dismiss'],
    setup() {
        const notificationsStore = useNotificationsStore();
        const drawerOpen = computed(() => notificationsStore.drawerOpen);
        const getIndex = computed(() => drawerOpen.value ? 0 : -1);

        const closeDrawer = () => {
            if(drawerOpen.value) {
                notificationsStore.drawerSwitchState();
            }
        };

        return {
            closeDrawer,
            drawerOpen,
            getIndex
        };
    },
    data() {
        return {
            overflow: false,
            expanded: false,
            requestStatus: null
        };
    },
    computed: {
        date() {
            const day = formatDate(this.notification.start_date, 'd-L');
            const now = formatDate(getLocalDate(), 'd-L');

            // check if the notification was issued today
            if (day === now) {
                // eg 15:12
                return formatDate(this.notification.start_date, 'HH:mm');
            } else if (DateTime.fromISO(this.notification.start_date).diffNow('day').days > -5) {
                // was it in the last 5 days? eg 'Thursday'
                return formatDate(this.notification.start_date, 'cccc');
            } else {
                // eg '12 Aug'
                return formatDate(this.notification.start_date, 'dd LLL');
            }
        },
        moreText() {
            if (this.expanded) {
                return 'View less';
            } else {
                return 'View more';
            }
        },
        moodleChannel() {
            return this.notification.channel_name === 'Moodle';
        },
        externalLink() {
            return this.notification ? this.notification.external_url : null;
        },
        sending() {
            return this.requestStatus === REQUEST_STATUS.REQUEST;
        },
        success() {
            return this.requestStatus === REQUEST_STATUS.SUCCESS;
        },
        error() {
            return this.requestStatus === REQUEST_STATUS.FAILURE;
        },
        canDismiss() {
            return !this.requestStatus || this.error;
        },
        isVisible() {
            return this.showViewMore || this.drawerOpen ;
        },
        extraCss() {
            return {
                'a-notification__message__moodle': this.moodleChannel
            };
        },
        itemRoute() {
            return { name: NOTIFICATION_ROUTES.ITEM, params: { id: this.notification.notification_id } };
        },
        isOversized() {
            return this.showViewMore && (this.moodleChannel || this.notification.message.length > 2000);
        }
    },
    watch: {
        isVisible(val) {
            if (val) {
                this.notificationTextOverflow();
            }
        }
    },
    mounted() {
        this.notificationTextOverflow();
    },
    methods: {
        /**
         * check to see if there is notification overflow...
         */
        notificationTextOverflow() {
            const elems = Array.from(this.$refs.notifItem.querySelectorAll('.a-notification__message__content > *'));
            this.overflow = elems.some(x => x.offsetWidth < x.scrollWidth);
        },
        closeDrawerAndSendGa(label, id) {
            this.closeDrawer();
            this.sendGa(label, id);
        },

        /**
         * Send the analytics event for a "view full [notification] details" click
         * @param {String} label The item title
         * @param {String|Number} id The item id
         */
        sendGa(label, id) {
            const url = `${UrlHelper.getBaseUri()}notifications/${id}`;

            sendCustomEvent({
                'event': EVENT_NAME.NOTIFICATION,
                'event_action_source': `Notifications ${this.actionSource}`,
                'action': '',
                'event_label': label,
                'event_url': url
            });
        },

        /**
         * Handle when a notification is dismissed
         */
        async handleDismiss() {

            try {
                this.requestStatus = REQUEST_STATUS.REQUEST;
                await Promise.all(this.notification.endpoints.map(async (endpoint) => {
                    await updateNotificationDelivery(endpoint, this.notification.notification_id);
                }));
                this.requestStatus = REQUEST_STATUS.SUCCESS;

                sendCustomEvent({
                    'event': EVENT_NAME.NOTIFICATION,
                    'event_action_source': `Notifications ${this.actionSource}`,
                    'action': 'dismiss notification',
                    'event_label': '',
                    'event_url': ''
                });

                this.emitDismissNotification();
            } catch (e) {
                this.requestStatus = REQUEST_STATUS.FAILURE;
            }
        },
        /**
         * Handler for expanding a notification's content
         */
        handleNotificationExpand() {
            this.$el.querySelector('.a-notification__message').classList.toggle('a-notification__message__expanded');
            this.expanded = !this.expanded;
            this.$emit('viewMore');
        },
        /**
         * Emit a dismiss event with the notification id and endpoint
         */
        emitDismissNotification() {
            this.$emit('dismiss', this.notification.notification_id);
        }
    }
};
</script>

<style>
    .a-notification__message__moodle table td:first-child {
        width: 40px;
    }

    .a-notification__message__moodle table table td:first-child {
        width: auto;
    }
</style>