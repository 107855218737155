﻿export const CALENDAR_ROUTES = {
    HOME: 'CALENDAR_HOME',
    ITEM: 'CALENDAR_ITEM'
};

export default [
    {
        path: '/calendar',
        name: CALENDAR_ROUTES.HOME,
        redirect: '/news'
    },
    {
        path: '/calendar/:slug',
        component: () => import('@pages/calendar/GenericCalendarPage.vue'),
        name: CALENDAR_ROUTES.ITEM,
        meta: {
            title: 'Calendar'
        }
    }
];