﻿import Fetch from '@api/fetch';

/**
 * Get a user's list of subscribed and unsubscribed widgets
 * @returns {Promise}
 */
export function getWidgetList() {
    return Fetch.internal.get('/api/widgets');
}

/**
 * Get data for a user widget
 * @param {Number} id The widget ID
 * @returns {Promise}
 */
export function getWidgetDataWithoutPaging(id) {
    return Fetch.internal.get(`/api/widgets/${id}`);
}

/**
 * Get data for a user widget
 * @param {Number} id The widget ID
 * @param {Number} startItem The index of the item to start from (starting from 0)
 * @param {Number} pageSize The number of items to return in the page
 * @returns {Promise}
 */
export function getWidgetDataWithPaging(id, startItem, pageSize) {
    return Fetch.internal.get(`/api/widgets/${id}?startItem=${startItem}&pageSize=${pageSize}`);
}

/**
 * Add a widget instance for the user
 * @param {Number} id The widget id
 * @returns {Promise}
 */
export function addWidgetSubscription(id) {
    return Fetch.internal.post(`/api/widgets/${id}`);
}

/**
 * Delete a widget instance for the user
 * @param {Number} id The widget id
 * @returns {Promise}
 */
export function deleteWidgetSubscription(id) {
    return Fetch.internal.delete(`/api/widgets/${id}`);
}

/**
 * Update the "page size" items for a user widget instance
 * @param {Number} id The widget id
 * @param {Number} value The number of items for the page Size
 * @returns {Promise}
 */
export function updateWidgetStateItems(id, value) {
    return Fetch.internal.put(`/api/widgets/${id}?items=${value}`);
}

/**
 * Update the order of widget items
 * @param {Array} widgets The widgets in order to be updated
 * @returns {Promise}
 */
export function updateWidgetsOrder(widgets) {
    return Fetch.internal.put('/api/widgets/order', { widgets });
}
