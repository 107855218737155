﻿import Fetch from '@api/fetch';

/**
 * Get a number of most recent staff notices regardless of channel
 * @param {Number} count the number of notices to fetch - if left blank, all items available will be returned
 * @returns {Promise}
 */
export function getNoticeboardRecentItems() {
    return Fetch.internal.get('/api/noticeboard');
}

/**
 * Get details for a noticeboard item
 * @param {String} itemId the id of the item to be fetched
 * @returns {Promise}
 */
export function getNoticeboardItem(itemId) {
    return Fetch.internal.get(`/api/noticeboard/${itemId}`);
}

/**
 * Close a noticeboard item
 * @param {String} itemId the id of the item to be closed
 * @returns {Promise}
 */
export function closeNoticeboardItem(itemId) {
    return Fetch.internal.delete(`/api/noticeboard/${itemId}`);
}

/**
 * Get noticeboard channels
 * @returns {Promise}
 */
export function getNoticeboardChannels() {
    return Fetch.internal.get('/api/noticeboard/channels');
}