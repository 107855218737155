﻿import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        appVersion: APP_VERSION,
        viewportSize: null,
        homePageImage: null
    }),

    persist: true,

    actions: {
        reset() {
            // this.appVersion - no change needed

            // this.viewportSize - no change needed. The viewport listener is ignorant of token expiry so we need to manually keep track of viewportSize when app data is reset

            this.homePageImage = null;
        }
    }
});

