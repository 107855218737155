﻿import { SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

const PgaRedirect = () => import('@pages/pga/PgaRedirect.vue');
const InformationPage = () => import('@pages/pga/InformationPage.vue');
const WebBoardPage = () => import('@pages/pga/WebBoardPage.vue');

export default [
    {
        path: '/pga',
        component: PgaRedirect,
        name: 'pga', // can't change this because of default page preferences
        meta: {
            requiresAuth: true,
            allowedRoles: ['pga'],
            title: 'Postgraduate Applicants',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.APPLICANTS
        }
    },
    {
        path: '/pga/information',
        component: InformationPage,
        name: 'pga.information',
        meta: {
            requiresAuth: true,
            allowedRoles: ['pga'],
            title: 'Information',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.APPLICANTS
        }
    },
    {
        path: '/pga/webboards',
        component: WebBoardPage,
        name: 'pga.webboards',
        meta: {
            requiresAuth: true,
            allowedRoles: ['pga'],
            title: 'Webboards',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.APPLICANTS
        }
    }
];