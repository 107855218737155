﻿/**
 * Preferences
 */
export const GET_PREFERENCES = {
    MODULE: 'GET_PREFERENCES_MODULE',
    TRAVELSECTIONS: 'GET_PREFERENCES_TRAVEL_SECTIONS',
    JOBSECTIONS: 'GET_PREFERENCES_JOB_SECTIONS',
    USERLINKS: 'GET_PREFERENCES_USERLINKS',
    GENERAL_DATA: 'GET_GENERAL_DATA'
};

export const SET_PREFERENCES = {
    MODULE: 'SET_PREFERENCES_MODULE',
    TRAVELSECTIONS: 'SET_PREFERENCES_TRAVEL_SECTIONS',
    JOBSECTIONS: 'SET_PREFERENCES_JOB_SECTIONS',
    USERLINKS: 'SET_PREFERENCES_USERLINKS',
    USERLINKS_ADD: 'SET_PREFERENCES_USERLINKS_ADD',
    GENERAL_DATA: 'SET_GENERAL_DATA'
};


/**
 * Identity
 */
export const DESIGN_THEME_DISPATCH = 'DISPATCH_DESIGN_THEME';