﻿const CmsContent = () => import('@pages/cms/CmsContentTemplate.vue');
const CmsNewsletterPage = () => import('@pages/cms/CmsNewsletterTemplate.vue');

export const CMS_ROUTES = {
    CMS: 'CMS',
    CMS_PARENT: 'CMS_PARENT',
    CMS_CHILD: 'CMS_CHILD',
    CMS_NEWSLETTER: 'CMS_NEWSLETTER'
};

export default [
    {
        path: '/cms',
        redirect: '/news'
    },
    {
        path: '/cms/:parent',
        component: CmsContent,
        name: CMS_ROUTES.CMS_PARENT,
        children: [
            {
                path: ':child',
                name: CMS_ROUTES.CMS_CHILD,
                component: CmsContent
            }            
        ]
    },
    {
        path: '/cms/:parent/item/:id',
        name: CMS_ROUTES.CMS_NEWSLETTER,
        component: CmsNewsletterPage
    }
];