﻿import { useTokenStore } from '@core-portal/stores/token.module';
import { getJwt } from '@core-portal/api/identity.api';
import { getBaseUri } from '@core-portal/scripts/siteBase.helper';
import { buildQueryParams } from '@core-portal/scripts/uri.helper';

/**
 * Check for auth data in the query string
 * @param {Object} to
 * @returns {Object} The 'next' object to redirect to
 */
export function checkAuthQueryString(to) {
    const { jwt, username } = to.query;
    let filteredQuery = to.query;
    let queryUpdated = false;

    if (jwt) {
        delete filteredQuery.jwt;
        delete filteredQuery.state;
        queryUpdated = true;
    }

    if (username) {
        delete filteredQuery.username;
        queryUpdated = true;
    }

    // nothing has changed
    if (!queryUpdated) {
        return null;
    }

    // return the updated route minus the auth query parameters
    const route = {
        path: to.path,
        query: filteredQuery,
        replace: true
    };

    // add the hash if one is present
    return to.hash
        ? { ...route, hash: to.hash }
        : route;
}

/**
 * Check's if there a valid JWT.
 * If not, get a new one
 * @param {Object} to
 * @param {String} to.path
 * @returns {Boolean} True/False if the JWT is set/valid - will trigger a redirect to get a JWT if it isn't set
 */
export async function validateJwt(to) {

    const tokenStore = useTokenStore();
    const jwt = tokenStore.jwt;

    if (!jwt) {
        const returnTo = to.path.startsWith('/')
            ? to.path.replace('/', '')
            : to.path;

        const q = {
            ...to.query
        };

        // capture any additional query params
        const query = q
            ? buildQueryParams(q)
            : null;

        to.hash
            ? getJwt(getBaseUri() + returnTo + query + to.hash)
            : getJwt(getBaseUri() + returnTo + query);

        // wait for 3 seconds to mitigate against race conditions
        await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
    }
}

/**
 * Determine the scroll behaviour based on user/browser preferences
 */
export function getScrollBehaviour() {

    // Check for disabled animations
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')?.matches ?? true;

    const scrollBehaviourKey = prefersReducedMotion
        ? '--scroll-behaviour-no-motion'
        : '--scroll-behaviour';

    // try and get the value from the stylesheet, default to 'auto'
    return getComputedStyle(document.documentElement)
        .getPropertyValue(scrollBehaviourKey)
        || 'auto;';
}
