﻿import { newDateFromISO, getLocalDate, convertToLocal, getDiffInMillis } from '@utilities/DateHelper';

/*
 * The filter values to be used for Learning Spaces
 */
export const starredFilterOptions = {
    any: {
        value: 'any',
        text: 'Any'
    },
    yes: {
        value: 'yes',
        text: 'Yes'
    },
    no: {
        value: 'no',
        text: 'No'
    }
};

/**
 * The filter values to be used for module periods
 */
export const periodFilterOptions = {
    all: {
        value: 'all',
        text: 'All Modules',
    },
    current: {
        value: 'current',
        text: 'Modules in progress'
    },
    past: {
        value: 'past',
        text: 'Past Modules'
    },
    future: {
        value: 'future',
        text: 'Future Modules'
    }
};

/**
 * Apply starred module filtering to a modules array
 * @param {String} filterType the type of space filtering to do
 * @param {Object[]} moduleData the module data recieved from the API
 * @param {Object[]} moduleData.items
 * @param {Object[]} moduleData.items.learningSpaces
 * @param {Object} starredFilterOptions The keys to use for filtering
 */
export function starredFilter(filterType, moduleData, starredFilterOptions) {

    if (!starredFilterOptions) {
        return moduleData;
    }

    // if `any` just return everything as is
    if (filterType === starredFilterOptions.any.value) {
        return moduleData;
    }

    for (let i = 0; i < moduleData.length; i++) {

        // get the space
        let space = moduleData[i];

        // exclude sections that should not be filtered || appraisal
        if (space.key === 'appraisal'
            || space.key === 'category'
            || space.key === 'dept'
            || space.key === 'digitalSkills'
            || space.key === 'ready'
            || space.key === 'securityTrainingPriority'
            || space.key === 'securityTrainingOptional'
        ) {
            continue;
        }

        // starred true/false
        const starred = filterType === starredFilterOptions.yes.value;

        space.items = space.items.filter(item => {
            return item.starred === starred;
        });
    }

    // return the modified array
    return moduleData;

}

/**
 * Apply period filtering to a modules array
 * @param {String} filterType the type of space filtering to do
 * @param {Object[]} moduleData the module data recieved from the API
 * @param {Object[]} moduleData.items
 * @param {Object} moduleData.items.courseInfo
 * @param {String} moduleData.items.courseInfo.startDate
 * @param {String} moduleData.items.courseInfo.filterEndDate
 * @param {String} [nowDate] The `now` ISO date string to be used in testing. Leave null to use the actual now date
 * @param {Object} periodFilterOptions The keys to use for filtering
 */
export function periodFilter(filterType, moduleData, nowDate, periodFilterOptions) {

    if (!periodFilterOptions) {
        return moduleData;
    }

    // if `all`, just return everything as is
    if (filterType === periodFilterOptions.all.value) {
        return moduleData;
    }

    // assign the now date, if one has been passed
    const NOW = nowDate ? newDateFromISO(nowDate) : getLocalDate();

    for (let i = 0; i < moduleData.length; i++) {

        let space = moduleData[i];

        if (filterType === periodFilterOptions.current.value) {

            space.items = space.items.filter(item => {

                // TODO: check what we do with `null` CourseInfo data
                if (!item.courseInfo) {
                    return true;
                }

                // module is current if start date in the past and end date in future OR not set
                return moduleDatesTest(
                    item.courseInfo.filterStartDate,
                    item.courseInfo.filterEndDate,
                    NOW,
                    (start, end) => start < 0 && (end > 0 || !end)
                );

            });

        }

        if (filterType === periodFilterOptions.past.value) {

            space.items = space.items.filter(item => {

                // TODO: check what we do with `null` CourseInfo data
                if (!item.courseInfo) {
                    return true;
                }

                return moduleDatesTest(
                    item.courseInfo.filterStartDate,
                    item.courseInfo.filterEndDate,
                    NOW,
                    (start, end) => end < 0
                );
            });

        }

        if (filterType === periodFilterOptions.future.value) {

            space.items = space.items.filter(item => {

                // TODO: check what we do with `null` CourseInfo data
                if (!item.courseInfo) {
                    return true;
                }

                return moduleDatesTest(
                    item.courseInfo.filterStartDate,
                    item.courseInfo.filterEndDate,
                    NOW,
                    start => start > 0
                );
            });
        }
    }

    // return the modified array
    return moduleData;

}

/**
 * Apply year filtering to a modules array if module is of type 'programme'
 * @param {String} filterType the type of space filtering to do
 * @param {Object[]} moduleData the module data recieved from the API
 * @param {Object[]} moduleData.key
 * @param {Object[]} moduleData.items
 * @param {Object[]} moduleData.items.lusiYearId
 */
export function yearFilter(filterType, moduleData) {
    if (!filterType) {
        return moduleData;
    } else {
        for (let i = 0; i < moduleData.length; i++) {
            // get the space
            let space = moduleData[i];
            if (space.key === 'programme') {
                space.items = space.items.filter(item => {
                    if (item.lusiYearId === filterType) {
                        return true;
                    }
                });
            }
        }
    }

    return moduleData;
}

/**
 * Do the date in-range heavy lifting. Takes a callback with (start, end) args for testing diff
 * @param {String} startDateISO The ISO string of the start date
 * @param {String} endDateISO The ISO string of the end date
 * @param {Object} nowDate The Luxon DateTime object for setting 'now'
 * @param {Function} testCallback The validity callback. Takes `start` and `end`. Returns boolean
 * @returns {Boolean}
 */
function moduleDatesTest(startDateISO, endDateISO, nowDate, testCallback) {

    // extract the dates into luxon objects
    const start = convertToLocal(startDateISO);
    const end = convertToLocal(endDateISO);

    let startDiff;
    let endDiff;

    if (start) {
        // calculate the diff in milliseconds
        startDiff = getDiffInMillis(start, nowDate);
    }

    if (end) {
        // calculate the diff in milliseconds
        endDiff = getDiffInMillis(end, nowDate);
    }
    // return the validation function
    return testCallback(startDiff, endDiff);
}
