﻿import { SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

const DigitalServicesLanding = () => import('@pages/digital-services/DsLanding.vue');
const DigitalServicesDetailsTemplate = () => import('@pages/digital-services/DsDetailsTemplate.vue');
const DigitalServicesHierarchyTemplate = () => import('@pages/digital-services/DsHierarchyTemplate.vue');

export const DSP_ROUTES = {
    LANDING: 'DSP_LANDING',
    HIERARCHY: 'DSP_HIERARCHY',
    VIEW: 'DSP_VIEW'
};

export default [
    {
        path: '/services',
        component: DigitalServicesLanding,
        name: DSP_ROUTES.LANDING,
        meta: {
            title: 'Services',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.SERVICES
        }
    },
    {
        path: '/services/:parent/:content?',
        component: DigitalServicesHierarchyTemplate,
        name: DSP_ROUTES.HIERARCHY,
        meta: {
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.SERVICES
        }
    },
    {
        path: '/services/:parent/:section/:content/:page?',
        component: DigitalServicesDetailsTemplate,
        name: DSP_ROUTES.VIEW,
        meta: {
            requiresAuth: true,
            subNav: true,
            subNavName: 'Service',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.SERVICES
        }
    }
];