﻿import { SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

const NewsLanding = () => import('@pages/news/NewsLanding.vue');
const ArticleWrapper = () => import('@pages/ArticleWrapper.vue');
const NewsTemplate = () => import('@pages/news/NewsTemplate.vue');
const AllNews = () => import('@pages/news/AllNews.vue');

export const NEWS_ROUTES = {
    HOME: 'news', // can't change this because of default page preferences
    ITEM: 'NEWS_ITEM',
    CHANNEL: 'NEWS_CHANNEL',
    ENDPOINT: 'NEWS_ENDPOINT',
    PREVIEW: 'NEWS_PREVIEW',
    PREVIEW_COLLEGE: 'NEWS_PREVIEW_COLLEGE'
};

export default [
    {
        path: '/news',
        component: NewsLanding,
        name: NEWS_ROUTES.HOME,
        meta: {
            title: 'News and Events',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/news/article',
        component: ArticleWrapper,
        name: 'article',
        // redirect to the news landing page if slug is not supplied
        redirect: '/news',
        children: [
            {
                path: ':slug',
                component: NewsTemplate,
                name: NEWS_ROUTES.ITEM,
                meta: {
                    title: 'News Item',
                    secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
                    subNav: true,
                    subNavName: 'News Article'
                }
            }
        ],
        meta: {
            title: 'News',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/news/channel',
        component: AllNews,
        name: 'allnews',
        // redirect to the news landing page if guid is not supplied
        redirect: '/news',
        children: [
            {
                path: ':guid',
                component: AllNews,
                name: NEWS_ROUTES.CHANNEL,
                meta: {
                    title: 'News Items',
                    secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
                    subNav: true,
                    subNavName: 'News Channel'
                }
            }
        ],
        meta: {
            title: 'All News',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/news/endpoint',
        component: AllNews,
        name: 'newsFeed',
        redirect: '/news',
        children: [
            {
                path: ':guid',
                component: AllNews,
                name: NEWS_ROUTES.ENDPOINT,
                meta: {
                    title: 'News',
                    secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
                    subNav: true,
                    subNavName: 'News Channel'
                }
            }
        ]
    }
];