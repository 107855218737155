﻿export const APP_THEME_KEYS = {
    DARK: 'THEME_DARK',
    LIGHT: 'THEME_LIGHT'
};

/**
 * Get the OS level colour theme preference.
 * Matches OS colour theme calculation in _Environment.cshtml
 */
export function getOsColourPreference() {
    const value = getComputedStyle(document.documentElement)
        .getPropertyValue('--colour-mode')
        .toString()
        .replace(/"/ig, '');

    return value.toLowerCase().includes('dark')
        ? APP_THEME_KEYS.DARK
        : APP_THEME_KEYS.LIGHT;
}

/**
 * Apply the class changes to the `<body>` element
 * Calculation and CSS values must match those used in server-side Model.ThemeCss
 * @param theme
 * @param {HTMLElement} targetElement
 */
export function updateColorPreferenceOnDom(theme, targetElement) {

    const themeCss = {
        light: 'light-mode',
        dark: 'dark-mode'
    };

    // theming is disabled
    if (targetElement.classList.contains('no-theme')) {
        targetElement.classList.remove(Object.values(themeCss));
        return;
    }

    targetElement.classList.remove(theme === APP_THEME_KEYS.DARK ? themeCss.light : themeCss.dark);
    targetElement.classList.add(theme === APP_THEME_KEYS.DARK ? themeCss.dark : themeCss.light);
}