﻿import { SECONDARY_NAV_ROUTE_KEYS } from '@config/constants';

export const NOTICEBOARD_ROUTES = {
    NOTICEBOARD: 'noticeboard',
    ITEM: 'noticeboard_item'
};

export default [
    {
        path: '/noticeboard',
        component: () => import('@pages/noticeboard/AllNoticeboard.vue'),
        name: NOTICEBOARD_ROUTES.NOTICEBOARD,
        meta: {
            requiresAuth: true,
            title: 'Noticeboard',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS
        }
    },
    {
        path: '/noticeboard/:item',
        component: () => import('@pages/noticeboard/NoticeboardItemTemplate.vue'),
        name: NOTICEBOARD_ROUTES.ITEM,
        meta: {
            requiresAuth: true,
            title: 'Noticeboard Item',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.NEWS,
            subNav: true,
            subNavName: 'Noticeboard Item'
        }
    }
];