﻿<template>
    <!-- Desktop MM -->
    <LargeMenu @mobile-menu-button-click="openMobileMenu = !openMobileMenu" />

    <!-- Secondary nav bar -->
    <component
        :is="secondaryNavigationComponent"
        v-if="secondaryNavigationComponent && secondaryMenuItems.length"
        :items="secondaryMenuItems"
    />

    <!-- Desktop SideMenu -->
    <component
        :is="sideMenuComponent"
        v-if="sideMenuComponent"
        :key-links="popular"
        :custom-links="myLinks"
        :profile="profile"
    />

    <!-- Mobile MM -->
    <component
        :is="drawerMenuComponent"
        v-if="drawerMenuComponent"
        :open="openMobileMenu"
        @close="openMobileMenu = false"
    />

    <!-- Mobile navigation bar -->
    <component
        :is="mobileBarComponent"
        v-if="mobileBarComponent"
        @close-mobile-drawer="openMobileMenu = false"
    />
</template>

<script>
import LargeMenu from '@core-portal/components/organisms/menus/LargeMenu.vue';

import { computed, defineAsyncComponent, ref } from 'vue';
import { useMenuStore } from '@core-portal/stores/menu.module';
import { storeToRefs } from 'pinia';
import { useStore } from 'vuex';
import { GET_PREFERENCES } from '@store/action-types';
import { IDENTITY, PREFERENCES } from '@store/getters';
import { useAppStore } from '@stores/app.module';
import { VIEWPORT } from '@core-portal/components/atoms/listeners/ViewportListener.vue';

const DrawerMenu = defineAsyncComponent(() =>
    import('@core-portal/components/organisms/menus/DrawerMenu.vue')
);

const SideMenu = defineAsyncComponent(() =>
    import('@core-portal/components/organisms/menus/SideMenu.vue')
);

const SecondaryNavigation = defineAsyncComponent(() =>
    import('@core-portal/components/organisms/menus/SecondaryNavigation.vue')
);

const MobileBar = defineAsyncComponent(() =>
    import('@core-portal/components/organisms/menus/MobileBar.vue')
);

export default {
    name: 'MegaMenuApp',
    components: {
        DrawerMenu,
        LargeMenu,
        MobileBar,
        SecondaryNavigation,
        SideMenu
    },
    setup() {
        const store = useStore();
        const appStore = useAppStore();
        const profile = computed(() => store.getters[IDENTITY.profile]);
        const authenticated = computed(() => profile.value.isAuthenticated);
        const menuStore = useMenuStore();
        const myLinks = computed(() => store.getters[PREFERENCES.userLinksPrefs]);
        
        // fetch MM data
        menuStore.fetch();
        const { mobileBar, popular, secondaryMenu: secondaryMenuItems } = storeToRefs(menuStore);
        
        // fetch user's personal links
        if (authenticated.value) {
            store.dispatch(GET_PREFERENCES.USERLINKS);
        }

        // fetch the dynamically imported components
        const smallViewport = computed(() => appStore.viewportSize <= VIEWPORT.XS);
        const secondaryNavigationComponent = computed(() => smallViewport.value ? null : SecondaryNavigation);
        const drawerMenuComponent = computed(() => smallViewport.value ? DrawerMenu : null);
        const sideMenuComponent = computed(() => authenticated.value && !smallViewport.value ? SideMenu : null);
        const mobileBarComponent = computed(() => mobileBar.value.length && smallViewport.value ? MobileBar : null);

        const openMobileMenu = ref(false);

        return {
            drawerMenuComponent,
            myLinks,
            mobileBarComponent,
            openMobileMenu,
            popular,
            profile,
            secondaryMenuItems,
            secondaryNavigationComponent,
            sideMenuComponent
        };
    }
};
</script>