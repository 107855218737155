﻿<template>
    <ViewportListener
        :sizes="viewportSizes"
        @change="viewportChange"
    />
</template>

<script>
/*
 * Global implementation of the Viewport Listener.
 * Store can be accessed at any time to get the current viewport's size.
 * Can be used instead of having multiple <viewport-listener>'s
 */
import ViewportListener, { VIEWPORT } from '@core-portal/components/atoms/listeners/ViewportListener.vue';
import { useAppStore } from '@stores/app.module';

export default {
    components: {
        ViewportListener
    },
    setup() {
        const viewportSizes = Object.values(VIEWPORT); // all sizes available
        const appStore = useAppStore();

        /**
         * Save the updated viewport size to the store
         */
        function viewportChange(size) {
            appStore.viewportSize = size;
        }

        return {
            viewportSizes,
            viewportChange
        };
    }
};
</script>