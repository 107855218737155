﻿<template>
    <div 
        class="c-spinner" 
        :style="wrapperStyle"
    >
        <div 
            class="c-spinner__circle c-spinner__circle--circle-1" 
            :style="circle1Style"
        />
        <div 
            class="c-spinner__circle c-spinner__circle--circle-2" 
            :style="circle1Style"
        />
    </div>
</template>

<script>
import { COLOURS } from '@config/constants';
export default {
    name: 'LoadingSpinner',
    props: {
        animationDuration: {
            type: Number,
            default: 1000
        },
        colour: {
            type: String,
            default: COLOURS.PRIMARY_RED
        },
        size: {
            required: false,
            validator: val => {
                return val === null || typeof val === 'number';
            },
            default: null
        }
    },
    computed: {
        defaultSize() {
            if (!this.size) {
                return 60;
            }

            return this.size;
        },
        circle1Style() {
            return Object.assign({
                borderTopColor: this.colour
            }, this.circleStyle);
        },

        circle2Style() {
            return Object.assign({
                borderBottomColor: this.colour
            }, this.circleStyle);
        },

        circleStyle() {
            return {
                borderWidth: `${this.defaultSize / 10}px`,
                animationDuration: `${this.animationDuration}ms`,
                width: `${this.defaultSize}px`,
                height: `${this.defaultSize}px`
            };
        },

        wrapperStyle() {
            return {
                minWidth: `${this.defaultSize}px`,
                minHeight: `${this.defaultSize}px`
            };
        }
    }
};
</script>

<style scoped>
    .o-bg-white,
    .o-bg-lightest-grey {
        & .c-spinner {
            color: var(--primary-red);
        }
    }
    .c-spinner, .c-spinner * {
        box-sizing: border-box;
    }

	.c-spinner {
		border-radius: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.c-spinner .c-spinner__circle {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		border: calc(60px / 10) solid transparent;
	}

	.c-spinner .c-spinner__circle--circle-1 {
		animation: c-spinner-animation 1s infinite;
	}

	.c-spinner .c-spinner__circle--circle-2 {
		animation: c-spinner-animation 1s infinite alternate;
	}

    @keyframes c-spinner-animation {
        0% {
            transform: rotate(0deg);

        }
        100%{
            transform: rotate(360deg);
        }
    }
</style>
