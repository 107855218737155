﻿import Fetch from '@api/fetch';
import { buildQueryParams } from '@core-portal/scripts/uri.helper';

export default {
    /**
     * Gets a list of event items
     * @param {String} channel the id of the news channel
     * @param {AbortSignal} signal
     * @returns {Promise}
     */
    getEventsByChannel(channel, signal = null) {
        const init = signal ? { signal } : null;
        return Fetch.internal.get(`/api/events/channelid/${channel}`, false, init);
    },

    getWelcomeWeekEvents() {
        return Fetch.internal.get('/api/events/welcomeweek');
    },

    /**
     * Get a list of tags related events by event and channel id
     * @param {String[]} eventId Guid of event to check tags of
     * @param {String[]} channelIds The Guids of the channels to look for related events in
     * @param {Number} index
     * @param {Number} pageSize How many items to return
     * @returns {Promise}
     */
    getTagsRelatedEvents(eventId, channelIds, index = 0, pageSize = 0) {
        const channels = encodeURIComponent(channelIds.join(','));
        return Fetch.internal.get(`/api/events/related?eventId=${eventId}&channelIds=${channels}&index=${index}&pageSize=${pageSize}`);
    }
};

/**
 * Gets a list of event types
 * @returns {Promise}
 */
export function getEventTypes() {
    return Fetch.internal.get('/api/events/filters/types');
}


/**
 * Gets an event item by slug
 * @param {String} slug
 * @returns {Promise}
 */
export function getEventBySlug(slug) {
    return Fetch.internal.get(`api/events/event/${slug}`);
}

/**
 * Gets an event item by Id
 * @param {String} eventId
 * @returns {Promise}
 */
export function getEventById(eventId) {
    return Fetch.internal.get(`/api/events/${eventId}`);
}

/**
 * Gets a welcome week event item by slug
 * @param {String} slug
 * @returns {Promise}
 */
export function getWelcomeWeekEventItem(slug) {
    return Fetch.internal.get(`api/events/welcomeweek/${slug}`);
}

/**
 * Get the highlighted events for a channel
 * @param {string} channelId
 * @param {number} [number = 4]
 * @param {AbortSignal} signal
 * @returns {Promise<Object[]>}
 */
export function getHighlightedEventsByChannel(channelId, number = 4, signal) {
    const init = signal ? { signal } : null;
    const params = buildQueryParams({ number });
    return Fetch.internal.get(`api/events/channelid/${channelId}/highlight${params}`, false, init);
}
