﻿export const NOTIFICATION_ROUTES = {
    NOTIFICATIONS: 'notifications',
    ITEM: 'notification.item'
};

export default [
    {
        path: '/notifications',
        component: () => import('@pages/notifications/NotificationsPage.vue'),
        name: NOTIFICATION_ROUTES.NOTIFICATIONS,
        meta: {
            requiresAuth: true,
            title: 'Notifications',
        }
    },
    {
        path: '/notifications/:id',
        component: () => import('@pages/notifications/NotificationTemplate.vue'),
        name: NOTIFICATION_ROUTES.ITEM,
        meta: {
            title: 'Notification',
            requiresAuth: true
        }
    }
];