﻿<template>
    <div class="org-grid-header__b">
        <span v-if="authenticated && academicWeek">
            {{ academicWeek }}
        </span>
    </div>
    <div class="org-grid-header__c">
        <a
            v-if="authenticated"
            href="https://apps.lancaster.ac.uk/"
        >
            Apps
        </a>
    </div>
    <div class="org-grid-header__d">
        <UserMenu
            ref="menuButton"
            :expanded="userMenuExpanded"
            @click="toggleUserMenu"
        />
    </div>
    <UserMenuPanel
        v-if="authenticated"
        v-show="userMenuExpanded"
        ref="menuItems"
        @navigate="toggleUserMenu"
    />
    <div class="org-grid-header__e">
        <NotificationLink v-if="authenticated" />
        <div
            id="login-link"
            class="org-header__login"
        >
            <LoginButton
                v-if="!authenticated"
                id="login-link__button"
                class="a-button a-button--login"
            >
                Login
            </LoginButton>
        </div>
        <TokenListener />
        <ColourThemeListener />
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { IDENTITY } from '@store/getters';
import { PROFILE_SUCCESS } from '@store/mutation-types';
import { useTokenStore } from '@core-portal/stores/token.module';
import { clearAuthenticatedData } from '@core-portal/scripts/identity.helper';
import { appVersion } from '@core-portal/api/profile.api';

import UserMenu from '@core-portal/components/atoms/header/UserMenu.vue';
import UserMenuPanel from '@core-portal/components/atoms/header/UserMenuPanel.vue';
import LoginButton from '@core-portal/components/atoms/header/LoginButton.vue';
import NotificationLink from '@core-portal/components/atoms/header/NotificationLink.vue';
import ColourThemeListener from '@core-portal/components/atoms/listeners/ColourThemeListener.vue';
import TokenListener from '@core-portal/components/atoms/listeners/TokenListener.vue';

export default {
    name: 'HeaderApp',

    components: {
        UserMenu,
        UserMenuPanel,
        NotificationLink,
        LoginButton,
        ColourThemeListener,
        TokenListener
    },

    props: {
        initialData: {
            type: Object,
            required: true
        }
    },

    setup(props) {

        const data = props.initialData;
        const store = useStore();
        const profile = computed(() => store.getters[IDENTITY.profile]);

        // set the profile based on the incoming prop
        if (data?.profile?.isAuthenticated) {
            // check if the username has changed (e.g. if an impersonation event has occured)
            const username = profile.value?.username;
            if (username && username !== data.profile.username) {
                clearAuthenticatedData();
            }

            // save token data to the store
            const tokenStore = useTokenStore();
            tokenStore.setJwt(data.jwt);

            // save the profile data in the store
            store.commit(PROFILE_SUCCESS, data.profile);
        } else {
            // if not authenticated then the session might have expired on the server
            clearAuthenticatedData();
        }

        // academic week
        const week = ref(null);
        week.value = data?.week;
        const currentAcademicWeek = computed(() => week.value);

        // user menu
        const expanded = ref(false);
        const userMenuExpanded = computed(() => expanded.value);
        const menuButton = ref(null);
        const userMenuBtnEl = computed(() => menuButton.value.userMenuBtnEl);
        const menuItems = ref(null);
        const userMenuLinkIds = computed(() => {
            return profile.value.isAuthenticated
                ? Array.from(menuItems.value.userMenuLinks).map(a => a.id) : [];
        });

        /**
         * Hide/display the user menu as appropriate
         */
        function toggleUserMenu() {
            expanded.value = !expanded.value;
        }

        /**
         * Event handler for checking if the user clicks off the menu
         * @param {MouseEvent} event
         */
        function offClickHandler(event) {
            // don't go any further if the target is the same as the caller
            if (event.target.id === userMenuBtnEl.value.id) {
                return;
            }

            for (let elem = event.target; elem; elem = elem.parentNode) {
                // if there is no parent then we are at the root
                if (!elem.parentNode) {
                    expanded.value = false;
                    return;
                }

                // all our target elements have classes
                if (!elem.className) {
                    continue;
                }

                // check if the element is included
                if (userMenuLinkIds.value.includes(elem.id)
                        || elem.classList.contains('a-navigation-link')
                        || elem.classList.contains('org-js-user-panel')
                ) {
                    return;
                }

                // nothing else matches, close the menu
                expanded.value = false;
            }
        }

        /**
         * Keyboard event handler for user menu.
         * @param {KeyboardEvent} event
         */
        function escapeHandler(event) {
            const { id } = document.activeElement;

            switch (event.key.toLowerCase()) {
            case 'escape':
                return expanded.value = false;
            case 'tab':
                if (!(userMenuLinkIds.value.includes(id) || id === userMenuBtnEl.value.id)) {
                    return expanded.value = false;
                }
            }
        }

        watch(() => expanded.value, (expanded) => {
            if (expanded) {
                document.addEventListener('keydown', escapeHandler);
                document.addEventListener('click', offClickHandler);
            } else {
                document.removeEventListener('keydown', escapeHandler);
                document.removeEventListener('click', offClickHandler);
            }
        });

        return {
            profile,
            currentAcademicWeek,
            userMenuExpanded,
            toggleUserMenu,
            menuButton,
            menuItems
        };
    },

    computed: {
        authenticated() {
            return this.profile.isAuthenticated;
        },
        academicWeek() {
            return this.currentAcademicWeek ? `Week ${this.currentAcademicWeek.description} (${this.currentAcademicWeek.term})` : null;
        }
    },

    mounted() {
        if (this.authenticated) {
            // log the app version being used
            appVersion();
        }
    }
};
</script>
