﻿export const SEARCH_ROUTES = {
    SEARCH: 'SEARCH_ROUTES_SEARCH'
};

export default [
    {
        path: '/search',
        component: () => import('@pages/search/SearchPage.vue'),
        name: SEARCH_ROUTES.SEARCH,
        meta: {
            title: 'Search Results'
        }
    }
];