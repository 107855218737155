﻿import { SECONDARY_NAV_ROUTE_KEYS, FORMS_ROUTES } from '@config/constants';
import { MYAREA_ROUTES } from '@routes/myArea';

export const REGISTRATION_ROUTES = {
    HOME: 'REGISTRATION_HOME' // can't change this because of default page preferences
};

export default [
    {
        path: '/registration',
        beforeEnter: (to, from, next) => {
            if (window.location.origin && window.location.origin.indexOf('portal') > -1) {
                // go to Registration Centre
                location.href = FORMS_ROUTES.REGISTRATION;
                return false;
            } else {
                return next({ name: MYAREA_ROUTES.PROFILE });
            }
        },
        name: REGISTRATION_ROUTES.HOME,
        meta: {
            requiresAuth: true,
            title: 'Registration Centre',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    }
];