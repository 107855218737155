import { SECONDARY_NAV_ROUTE_KEYS, ASK_ROUTES } from '@config/constants';

const MyAreaRedirect = () => import('@pages/my-area/MyAreaRedirect.vue');
const DashboardPage = () => import('@pages/my-area/DashboardPage.vue');
const LibraryPage = () => import('@pages/my-area/LibraryPage.vue');

const SingleModulePage = () => import('@pages/my-area/SingleModulePage.vue');

// Student Record
const StudentRecordOptionsPage = () => import('@pages/my-area/studentRecord/StudentRecordOptionsPage.vue');

// User
const ProfilePage = () => import('@pages/my-area/ProfilePage.vue');
const PreferencesPage = () => import('@pages/my-area/PreferencesPage.vue');

// Welcome Week
const AllWelcomeWeekEvents = () => import('@pages/events/AllWelcomeWeekEvents.vue');
const WelcomeWeekEventTemplate = () => import('@pages/events/WelcomeWeekEventTemplate.vue');



export const MYAREA_ROUTES = {
    HOME: 'MYAREA_HOME',
    DASHBOARD: 'dashboard', // can't change this because of default page preferences
    LIBRARY: 'MYAREA_LIBRARY', // can't change this because of default page preferences
    MODULE: {
        HOME: 'modules', // can't change this because of default page preferences
        DETAILS: 'MYAREA_MODULE_DETAILS'
    },
    SETTINGS: 
    {
        HOME: 'MYAREA_SETTINGS',
        DEFAULT_PAGE: 'MYAREA_SETTINGS_DEFAULTPAGE',
        DASHBOARD_PAGE: 'MYAREA_SETTINGS_DASHBOARD_PAGE',
        TRAVEL_PAGE: 'MYAREA_SETTINGS_TRAVEL_PAGE',
        MODULES_PAGE: 'MYAREA_SETTINGS_MODULES_PAGE',
        LINKS_PAGE: 'MYAREA_SETTINGS_LINKS_PAGE',
        THEME_PAGE: 'MYAREA_SETTINGS_THEME_PAGE',
        JOB_PAGE: 'MYAREA_SETTINGS_JOB_PAGE',
        NEWS_PAGE: 'MYAREA_SETTINGS_NEWS_PAGE'
    },
    PROFILE: 'MYAREA_PROFILE',
    STUDENTRECORD: 'MYAREA_STUDENTRECORD',
    TIMETABLE: 'timetable', // can't change this because of default page preferences
    TRAINING: 'MYAREA_TRAINING',
    WELCOME_WEEK: {
        CURRENT: 'MYAREA_WELCOME_WEEK_CURRENT',
        PREVIOUS: 'MYAREA_WELCOME_WEEK_PREVIOUS',
        EVENTS: 'MYAREA_WELCOME_WEEK_EVENTS',
        EVENTS_ITEM: 'MYAREA_WELCOME_WEEK_EVENTS_ITEM'
    },
    MY_PROGRAMME: 'MY_PROGRAMME'
};

export default [
    {
        path: '/my-area',
        component: MyAreaRedirect,
        name: MYAREA_ROUTES.HOME,
        meta: {
            title: 'My Area',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }

    },
    {
        path: '/my-area/dashboard',
        component: DashboardPage,
        name: MYAREA_ROUTES.DASHBOARD,
        meta: {
            title: 'Dashboard',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/modules',
        component: () => import('@pages/my-area/ModulesPage.vue'),
        name: MYAREA_ROUTES.MODULE.HOME,
        meta: {
            title: 'Moodle',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA,
            dataKey: 'moodle',
            filter: true
        }
    },
    {
        path: '/my-area/modules/details',
        component: SingleModulePage,
        name: MYAREA_ROUTES.MODULE.DETAILS,
        meta: {
            title: 'Module details',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA,
            dataKey: 'moodle'
        }
    },
    {
        path: '/my-area/timetable',
        component: () => import('@pages/my-area/TimetableNewPage.vue'),
        name: MYAREA_ROUTES.TIMETABLE,
        meta: {
            title: 'Timetable',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/library',
        component: LibraryPage,
        name: MYAREA_ROUTES.LIBRARY,
        meta: {
            title: 'Library',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/studentrecord',
        component: StudentRecordOptionsPage,
        name: MYAREA_ROUTES.STUDENTRECORD,
        meta: {
            requiresAuth: true,
            allowedRoles: ['student'],
            title: 'Student Record',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/training',
        component: () => import('@pages/my-area/ModulesPage.vue'),
        name: MYAREA_ROUTES.TRAINING,
        meta: {
            title: 'Training',
            requiresAuth: true,
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA,
            dataKey: 'training',
            filter: false
        }
    },
    {
        path: '/my-area/profile',
        component: ProfilePage,
        name: MYAREA_ROUTES.PROFILE,
        meta: {
            requiresAuth: true,
            title: 'User Profile',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/preferences',
        redirect: {
            name: MYAREA_ROUTES.SETTINGS.HOME
        }
    },
    {
        path: '/my-area/settings',
        component: PreferencesPage,
        name: MYAREA_ROUTES.SETTINGS.HOME,
        meta: {
            requiresAuth: true,
            title: 'User Settings',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        },
        children: [
            {
                path: 'default-page',
                name: MYAREA_ROUTES.SETTINGS.DEFAULT_PAGE,
                component: () => import('@pages/my-area/settings/DefaultPageSectionSettings.vue')
            },
            {
                path: 'dashboard',
                name: MYAREA_ROUTES.SETTINGS.DASHBOARD_PAGE,
                component: () => import('@pages/my-area/settings/DashboardSectionSettings.vue')
            },
            {
                path: 'travel',
                name: MYAREA_ROUTES.SETTINGS.TRAVEL_PAGE,
                component: () => import('@pages/my-area/settings/TravelSectionSettings.vue')
            },
            {  
                path: 'modules',
                name: MYAREA_ROUTES.SETTINGS.MODULES_PAGE,
                component: () => import('@pages/my-area/settings/ModulesSectionSettings.vue')

            },
            {
                path: 'user-links',
                name: MYAREA_ROUTES.SETTINGS.LINKS_PAGE,
                component: () => import('@pages/my-area/settings/MyLinksSectionSettings.vue')

            },
            {
                path: 'theme',
                name: MYAREA_ROUTES.SETTINGS.THEME_PAGE,
                component: () => import('@pages/my-area/settings/ThemeSectionSettings.vue')

            },
            {
                path: 'jobs',
                name: MYAREA_ROUTES.SETTINGS.JOB_PAGE,
                component: () => import('@pages/my-area/settings/JobSectionSettings.vue')

            },
            {
                path: 'news',
                name: MYAREA_ROUTES.SETTINGS.NEWS_PAGE,
                component: () => import('@pages/my-area/settings/NewsSectionSettings.vue')

            },


        ]
    },
    {
        path: '/my-area/welcome-week',
        component: AllWelcomeWeekEvents,
        name: MYAREA_ROUTES.WELCOME_WEEK.CURRENT,
        meta: {
            requiresAuth: true,
            title: 'Welcome Week - Current Events',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/welcome-week/previous',
        component: AllWelcomeWeekEvents,
        name: MYAREA_ROUTES.WELCOME_WEEK.PREVIOUS,
        meta: {
            requiresAuth: true,
            title: 'Welcome Week - Previous Events',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/welcome-week/events',
        name: MYAREA_ROUTES.WELCOME_WEEK.EVENTS,
        redirect: {
            name: MYAREA_ROUTES.WELCOME_WEEK.CURRENT
        }
    },
    {
        path: '/my-area/welcome-week/events/:slug',
        component: WelcomeWeekEventTemplate,
        name: MYAREA_ROUTES.WELCOME_WEEK.EVENTS_ITEM,
        meta: {
            requiresAuth: true,
            title: 'Welcome Week Event',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    },
    {
        path: '/my-area/my-programme',
        name: MYAREA_ROUTES.MY_PROGRAMME,
        beforeEnter: () => {
            // go to ASK My Programme page
            location.href = ASK_ROUTES.MY_PROGRAMME;
            return false;
        },
        meta: {
            requiresAuth: true,
            title: 'My Programme Redirect',
            secondaryKey: SECONDARY_NAV_ROUTE_KEYS.MYAREA
        }
    }
];