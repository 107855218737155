﻿import { defineStore } from 'pinia';
import { getWidgetList } from '@api/widgets';



export const useWidgetsStore = defineStore('widgets', {
    state: () => ({
        widgetsData: {
            serviceStatus: [],
            subscribed: [],
            unsubscribed: []
        }
    }),

    persist: true,


    getters: {
        getData: (state) => state.widgetsData,

        /**
         * Get the list of subscribed widgets for a user.
         * @returns {Object[]}
         */
        getSubscribed: (state) => {
            return state.widgetsData.subscribed || [];
        },

        /**
         * Get the list of unsubscribed widgets for a user.
         * @returns {Object[]}
         */
        getUnsubscribed: (state) => {
            return state.widgetsData.unsubscribed || [];
        },

        /**
         * Get the service status of the data returned in case of issues or blocks.
         * @param {any} state
         */
        getServiceStatus: (state) => {
            return state.widgetsData.serviceStatus || [];
        }
    },

    actions: {
        reset() {
            this.$reset();
        },


        /* Fetch the widget data via the API and save in the store.*/
        async fetchData() {
            try {
                this.widgetsData = await getWidgetList();
            } catch (e) {
                // eslint-disable-next-line
                console.log(e);
            }
        }
    }
});