﻿import Fetch from '@api/fetch';

/**
 * Get a single layer of DSP content
 * @param {String} [section = null]
 * @param {String} [subSection = null]
 * @param {Boolean} [clearCache = false]
 * @returns {Promise<Object[]>}
 */
export function getDspSections(section = null, subSection = null, clearCache = false) {
    // the base url
    let uri = '/api/services';

    // add any additional sections
    [section, subSection]
        .filter(x => x)
        .map(x => uri += `/${x}`);

    return Fetch.internal.get(uri, clearCache);
}

/**
 * Get the page content for a DSP contenet section
 * @param {String} section
 * @param {String} subSection
 * @param {String} content
 * @returns {Promise<Object[]>}
 */
export function getDspContent(section, subSection, content) {
    let uri = [section, subSection, content].join('/');

    return Fetch.internal.get(`/api/services/${uri}`);
}