﻿<template>
    <div
        class="m-notifications"
        :class="classes"
    >
        <div
            v-for="item in notifications"
            :id="generateNotificationElementId(item.notification_id)"
            :key="item.notification_id"
        >
            <NotificationItem
                :notification="item"
                :show-view-more="true"
                :action-source="actionSource"
                @dismiss="handleDismiss(item.notification_id)"
            />
        </div>
        <div
            v-if="loaded && notifications.length === 0"
            class="m-notifications__no-notifications o-container u-pad-t-1"
        >
            <InfoCircle2 class="m-notifications__dismiss-wrapper-icon svg-inline--fa fa-w-16" />
            &nbsp;You do not have any notifications at the moment.
        </div>
        <div
            v-else
            class="m-notifications__dismiss-wrapper-container u-pad-t-05"
        >
            <div class="m-notifications__dismiss-wrapper">
                <div class="o-container">
                    <div class="m-grid-notifications-dismiss">
                        <div>
                            <span v-if="error">
                                <InfoCircle2 class="m-notifications__dismiss-wrapper-icon svg-inline--fa fa-w-16" />
                                &nbsp;Some notifications could not be dismissed.
                            </span>
                        </div>
                        <div>
                            <GenericButton
                                v-if="notifications.length > 1"
                                flavour="secondary-red"
                                title="Dismiss all notifications"
                                class="a-button--notifications a-button--full-width-xxs"
                                :disabled="sending"
                                @click="handleDismissAll"
                            >
                                <template #button-text>
                                    <span v-if="!sending">Dismiss All</span>
                                    <div
                                        v-if="sending"
                                        class="u-flex-column"
                                        aria-busy="true"
                                    >
                                        <LoadingSpinner
                                            colour="#fff"
                                            :size="19"
                                        />
                                    </div>
                                </template>
                            </GenericButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import GenericButton from '@atoms/buttons/GenericButton.vue';
import { IconInfoCircle2 } from '@cisweb/icon-library-vue3';
import LoadingSpinner from '@atoms/animations/LoadingSpinner.vue';
import NotificationItem from '@atoms/notifications/NotificationItem.vue';

import { computed } from 'vue';
import { useNotificationsStore } from '@stores/notifications.module';
import { updateNotificationDelivery } from '@api/notifications.api';
import { REQUEST_STATUS, EVENT_NAME } from '@config/constants';
import { sendCustomEvent } from '@utilities/AnalyticsHelper.js';

export default {
    components: {
        GenericButton,
        InfoCircle2: IconInfoCircle2,
        LoadingSpinner,
        NotificationItem
    },
    props: {
        actionSource: {
            type: String,
            required: true
        }
    },
    setup() {
        const notificationsStore = useNotificationsStore();
        const notifications = computed(() => notificationsStore.getNotifications(notificationsStore.userEndpoint));
        const fetchStatus = computed(() => notificationsStore.getRequestStatus(notificationsStore.userEndpoint));

        /**
         * Remove an individual item from the store
         */
        function removeItem(id) {
            notificationsStore.removeNotificationById(notificationsStore.userEndpoint, id);
        }

        return {
            notifications,
            fetchStatus,
            removeItem
        };
    },
    data() {
        return {
            dismissAllStatus: null
        };
    },
    computed: {
        classes() {
            return {
                'o-bg-green': this.notifications.length > 0
            };
        },
        height() {
            if (!this.$el) {
                return null;
            }
            return this.$el.getBoundingClientRect().height;
        },
        sending() {
            return this.dismissAllStatus === REQUEST_STATUS.REQUEST;
        },
        error() {
            return this.dismissAllStatus === REQUEST_STATUS.FAILURE;
        },
        loaded() {
            return this.fetchStatus === REQUEST_STATUS.SUCCESS;
        }
    },
    mounted() {
        this.$el.addEventListener('animationend', this.animationEndHandler);
    },
    beforeUnmount() {
        this.$el.removeEventListener('animationend', this.animationEndHandler);
    },
    methods: {
        /**
         * User has dismissed a notification. Add the animation class
         * @param {Number} notificationId
         */
        handleDismiss(notificationId) {
            const id = this.generateNotificationElementId(notificationId);
            this.$el.querySelector(`#${id}`).classList.add('m-notification--dismiss');
        },
        /**
         * Helper method for generating an element id
         * @param {Number} notificationId
         * @returns {String}
         */
        generateNotificationElementId(notificationId) {
            return `notification-${notificationId}`;
        },
        /**
         * Event handler for when the dismiss animation has completed
         * @param {AnimationEvent} event
         */
        animationEndHandler(event) {
            if (event.animationName === 'dismiss-notification') {
                const id = parseInt(event.target.id.split('-')[1], 10);
                if (isNaN(id)) {
                    return;
                }
                this.removeItem(id);
            }
        },
        /**
         * Handle when all notifications are dismissed
         */
        async handleDismissAll() {
            sendCustomEvent({
                'event': EVENT_NAME.NOTIFICATION,
                'event_action_source': `Notifications ${this.actionSource}`,
                'action': 'dismiss all notifications',
                'event_label': '',
                'event_url': ''
            });

            this.dismissAllStatus = REQUEST_STATUS.REQUEST;
            if (!Array.isArray(this.notifications)) {
                return;
            }

            let endpointsList = [];
            // get full list of endpoints
            this.notifications.forEach(n => endpointsList.push(n.endpoints));

            // flatten array
            endpointsList = [].concat(...endpointsList);

            // remove duplicates
            endpointsList = [...new Set(endpointsList)];

            // map notification ids to endpoints
            let map = new Map();
            this.notifications.forEach(n => {
                n.endpoints.forEach(endpoint => {
                    map.has(endpoint) ?
                        map.set(endpoint, [...map.get(endpoint), n.notification_id]) : map.set(endpoint, [n.notification_id]);
                });
            });

            // pipe the request
            let requests = [];
            map.forEach((value, key) => requests.push(updateNotificationDelivery(key, value)
                .then(value.forEach(id => this.handleDismiss(id)))));

            try {
                await Promise.all(requests);
                this.dismissAllStatus = REQUEST_STATUS.SUCCESS;
            } catch (e) {
                this.dismissAllStatus = REQUEST_STATUS.FAILURE;
            }
        }
    }
};
</script>