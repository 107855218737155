﻿import { useTokenStore } from '@core-portal/stores/token.module';
import { store } from '@store';
import { IDENTITY } from '@store/getters';
import { SITEMODE } from '@config/constants';
import { clearAuthenticatedData } from '@core-portal/scripts/identity.helper';

/**
 * Static class that wraps the native Fetch API
 */
export default class {

    /**
     * General fetch function. Defaults to GET
     * but requires the init object to specify otherwise.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Supplying_your_own_request_object
     * @param {String} url the url to fetch
     * @param {Object} [init=null] the initialisation options
     * @param {String} [init.method='GET'] the HTTP verb to use. Defaults to 'GET'
     */
    static async fetch(url, init = null, returnType = 'json') {
        // do the fetch
        const response = init
            ? await fetch(url, init)
            : await fetch(url);
        
        // return the json if all good
        if (response.ok) {
            switch (returnType) {
            case 'json':
                return await response.json();
            case 'arrayBuffer':
                return await response.arrayBuffer(); 
            default:
                return await response.json();
            }
        }
        // otherwise throw an error
        const code = response.status;
        const info = response.statusText;
        const text = await response.text();

        // check if the token has expired...
        if (code === 401 && info === 'Token has expired') {
            clearAuthenticatedData();
        }

        throw { code, info, text };
    }

    /**
     * Getter for the Authorization property header
     * @return {Headers}
     */
    static get internalHeader() {
        const tokenStore = useTokenStore();
        const jwt = tokenStore.jwt;
        const siteMode = store.getters[IDENTITY.siteMode];

        let token = 'anonymous_student'; // default
        if (jwt) {
            token = jwt;
        } else {
            if (siteMode === SITEMODE.INTRANET) {
                token = 'anonymous_staff';
            }
        }

        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
    }

    /**
     * Wrapper for handling local api calls to differentiate between
     * dev, test and live builds
     * @todo Handle the different scenarios
     */
    static get internal() {
        return {
            /**
             * Wrapper for the `get` method. Handles calling the Indigo api
             * @param {string} url
             * @param {boolean} [clearCache=false]
             * @param {RequestInit}
             */
            get: (url, clearCache = false, init = null) => {
                let endpoint = this.prefixIndigoApi(url);
                endpoint = this.clearCache(endpoint, clearCache);

                return this.fetch(endpoint, {
                    method: 'GET',
                    credentials: 'include',
                    headers: this.internalHeader,
                    ...init
                });
            },
            /**
             * Wrapper for the `put` method. Handles calling the Indigo api
             * @param {String} url
             * @param {Object|Array} data
             */ 
            put: (url, data) => {
                let endpoint = this.prefixIndigoApi(url);
                endpoint = this.clearCache(endpoint);

                return this.fetch(endpoint, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: this.internalHeader,
                    body: JSON.stringify(data)
                });
            },
            /**
             * Wrapper for the `delete` method. Handles calling the Indigo api
             * @param {String} url
             * @param {Object|Array} data
             */
            delete: (url, data) => {
                let endpoint = this.prefixIndigoApi(url);
                endpoint = this.clearCache(endpoint);

                return this.fetch(endpoint, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: this.internalHeader,
                    body: JSON.stringify(data)
                });
            },
            /**
             * Wrapper for the `post` method. Handles calling the Indigo api
             * @param {String} url
             * @param {Object|Array} data
             * @param {Boolean} [formData = false]
             */
            post: (url, data, formData = false) => {
                let endpoint = this.prefixIndigoApi(url);
                endpoint = this.clearCache(endpoint);

                // only JSON-ify the data if not using multipart form data
                const body = formData
                    ? data
                    : JSON.stringify(data);

                const tokenStore = useTokenStore();
                const jwt = tokenStore.jwt;

                const headers = !formData
                    ? this.internalHeader
                    : {
                        Authorization: `Bearer ${jwt}`,
                        'Content-Type': 'multipart/form-data'
                    };

                return this.fetch(endpoint, {
                    method: 'POST',
                    credentials: 'include',
                    headers,
                    body
                });
            },
            getImageData: (url, mimeType = 'image/jpeg', clearCache = false) => {
                let endpoint = this.prefixIndigoApi(url);
                endpoint = this.clearCache(endpoint, clearCache);

                const tokenStore = useTokenStore();
                const jwt = tokenStore.jwt;

                const headers = {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': mimeType
                };

                return this.fetch(endpoint,
                    {
                        method: 'GET',
                        credentials: 'include',
                        headers
                    },
                    'arrayBuffer');
            },
        };
    }

    /**
     * Prepends any internal api calls with the correct base.
     * Means we can simply call 'api/whatever'
     * @param {String} url
     */
    static prefixIndigoApi(url) {
        const base = document.getElementsByTagName('base')[0]
            ? document.getElementsByTagName('base')[0].href
            : '';
        // remove opening slash, if present
        url = url.startsWith('/') ? url.slice(1) : url;
        return base + url;
    }

    /**
     * Add the clearCache property to the end of the API request
     * if it has been specified in the query string or explicitly
     * passed as an argument
     * @param {String} url
     * @param {Boolean} [clearCache = false]
     */
    static clearCache(url, clearCache = false) {
        // check if caching is to be ignored (case insensitive)
        if (clearCache || window.location.search.match(/(\?|&)nocache=ison(&|$)/i)) {
            // append the clearCache to the end
            return url + (url.indexOf('?') > 0 ? '&' : '?') + 'clearCache=true';
        }
        return url;
    }
}