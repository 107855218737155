﻿<template>
    <button
        v-if="!authenticated"
        @click="login"
    >
        Login
    </button>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { IDENTITY } from '@store/getters';
import { getJwt } from '@core-portal/api/identity.api';

export default {
    setup() {
        const store = useStore();
        const profile = computed(() => store.getters[IDENTITY.profile]);

        return {
            profile
        };
    },

    computed: {
        authenticated() {
            return this.profile.isAuthenticated;
        },

    },

    methods: {
        login() {
            getJwt();
        },
    
    }
};
</script>
