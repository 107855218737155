﻿<template>
    <div>
        <!-- to be initialised only once in the entire app! -->
        <ViewportGlobalListener />

        <span
            v-observe-visibility="handleScroll"
            style="height:1px"
        />

        <SiteActionNotificationRow />

        <!--Ask user for permission to use Google Analytics while unauthenticated-->
        <component
            :is="gaConsentModal"
            v-if="gaConsentModal"
            :role="userRole"
        />

        <!-- Promotion -->
        <component
            :is="promotionComponent"
            v-if="promotionComponent && !closePromotionRow"
            bg-class="o-bg-green"
            role="complementary"
            aria-label="information box"
            @close="closePromotionRow=true"
        />

        <!-- Alert -->
        <component
            :is="alertComponent"
            v-if="alertComponent && !closeAlertRow"
            bg-class="o-bg-darkest-grey o-bg-lilac--dark-mode"
            role="complementary"
            alert="alert box"
            @close="closeAlertRow=true"
        />

        <div
            id="content"
            tabindex="-1"
        >
            <router-view />
        </div>

        <!-- Back to top button -->
        <component
            :is="backToTopComponent"
            v-if="backToTopComponent"
        />
    </div>
</template>

<script>
import { defineAsyncComponent, computed } from 'vue';
import { outputVersionToConsole } from '@scripts/misc';
import { mapGetters } from 'vuex';
import { IDENTITY } from '@store/getters';

import { useAppStore } from '@stores/app.module';
import { useNewsStore } from '@stores/news.module';


import { CRAWLERS } from '@config/constants';
import { setViewportTracking } from '@utilities/AnalyticsHelper.js';

import { useNotificationsStore } from '@stores/notifications.module';

import SiteActionNotificationRow from '@organisms/rows/site-action/SiteActionNotificationRow.vue';
import ViewportGlobalListener from '@core-portal/components/atoms/listeners/ViewportGlobalListener.vue';

const ConsentModal = defineAsyncComponent(() =>
    import('@molecules/site-action/CookiesConsentModal.vue')
);

const BackToTop = defineAsyncComponent(() =>
    import('@atoms/buttons/BackToTop.vue')
);

const AlertRow = defineAsyncComponent(() =>
    import('@organisms/rows/alerts/AlertRow.vue')
);

const PromotionRow = defineAsyncComponent(() =>
    import('@organisms/rows/promotions/PromotionRow.vue')
);

outputVersionToConsole(APP_VERSION);

export default {
    name: 'EntryPoint',
    components: {
        AlertRow,
        BackToTop,
        ConsentModal,
        PromotionRow,
        SiteActionNotificationRow,
        ViewportGlobalListener
    },

    props: {
        initialData: {
            type: Object,
            required: true
        }
    },

    setup() {
        const appStore = useAppStore();
        const newsStore = useNewsStore();
        const notificationsStore = useNotificationsStore();

        const hasPriorityNotification = computed(() => notificationsStore.priorityNotification?.title ? true : false);
        const hasPromotionNotification = computed(() => notificationsStore.promotionNotification?.title ? true : false);

        return {
            hasPriorityNotification,
            hasPromotionNotification,
            appStore,
            newsStore
        };
    },

    data: () => ({
        closeAlertRow: false,
        closePromotionRow: false,
        openMobileMenu: false,
        showBackToTop: false,
        isBot: null
    }),

    computed: {
        ...mapGetters([
            IDENTITY.authenticated,
            IDENTITY.profile,
            IDENTITY.staff,
            IDENTITY.student
        ]),
        // user role
        userRole() {
            if (this[IDENTITY.staff] && this[IDENTITY.student]) {
                return 'Dual';
            } else if (this[IDENTITY.staff]) {
                return 'Staff';
            } else if (this[IDENTITY.student]) {
                return 'Student';
            }
            return 'Anonymous';
        },
        /**
         * Dynamically import the promotion row component,
         * if needed
         */
        promotionComponent() {
            if (this.hasPromotionNotification) {
                return PromotionRow;
            }
            return null;
        },
        /**
         * Dynamically import the alert row component,
         * if needed
         */
        alertComponent() {
            if (this.hasPriorityNotification) {
                return AlertRow;
            }
            return null;
        },
        /**
         * Dynamically import the back to top component
         */
        backToTopComponent() {
            if (this.showBackToTop) {
                return BackToTop;
            }
            return null;
        },
        /**
         * Dynamically import the Cookies Consent Modal
         */
        gaConsentModal() {
            if (!this.isBot && localStorage.getItem('consentMode') === null) {
                return ConsentModal;
            }

            return null;
        },
    },

    async beforeMount() {
        // check if page is requested by a crawler
        const useragent = navigator.userAgent.toLowerCase();
        this.isBot = CRAWLERS.some(bot => useragent.includes(bot));

        // Populate the store if data is passed with the HTML
        await this.processInitData();

        // Let the non-vue code know that the vue part is ready
        window.dispatchEvent(new CustomEvent('vueapploaded'));

        // Track viewport data when browser window is resized or zoomed in/out - consent checks applied within
        setViewportTracking();
    },

    methods: {
        /**
         * Populate the store, clear data etc. as required
         */
        async processInitData() {
            const data = this.$root.$props.initialData;

            // Set the home page image
            this.appStore.homePageImage = data.homePageImage;

            this.newsStore.setGtmNewsTags(data.gtmNewsTags);
        },
        /**
         * Check to see if the "back to top" button should be visible
         * @param {Boolean} visible
         */
        handleScroll(visible) {
            this.showBackToTop = !visible;
        }
    }
};
</script>