﻿import { store } from '@store';
import * as MUTATIONS from '@store/mutation-types';
import { useTokenStore } from '@core-portal/stores/token.module';
import { removeSiteActionModalSessionFlags } from '@utilities/SiteActionHelper';
import { useNotificationsStore } from '@stores/notifications.module';
import { useJobsStore } from '@stores/jobs.module';
import { useNoticeboardStore } from '@stores/noticeboard.module';
import { useWidgetsStore } from '@stores/widgets.module';
import { useMenuStore } from '@core-portal/stores/menu.module';
import { useEventsStore } from '@stores/events.module';
import { useAppStore } from '@stores/app.module';
import { useDspStore } from '../../stores/dsp.module';
import { useNewsStore } from '../../stores/news.module';


/**
 * Clear any data that may be dependent on the user/user-type
 * and reset the state back to it's initial form
 */
export function clearAuthenticatedData() {

    // clear identity first
    const tokenStore = useTokenStore();
    tokenStore.reset();
    store.commit(MUTATIONS.IDENTITY_RESET);

    /**
     * Clear the Pinia stores
     */
    const notificationsStore = useNotificationsStore();
    notificationsStore.reset();

    const jobsStore = useJobsStore();
    jobsStore.reset();

    const noticeboardStore = useNoticeboardStore();
    noticeboardStore.reset();

    const widgetStore = useWidgetsStore();
    widgetStore.reset();
  
    const menuStore = useMenuStore();
    menuStore.$reset();
  
    const eventsStore = useEventsStore();
    eventsStore.reset();

    const appStore = useAppStore();
    appStore.reset();

    const dspStore = useDspStore();
    dspStore.reset();

    const newsStore = useNewsStore();
    newsStore.reset();

    /**
     * Clear the Vuex stores
     * - alphabetically listed to make it easier to double check we've not missed anything
     * - Vuex mutation are never asynchronous
     */
    // IDENTITY_RESET already done in code above
    store.commit(MUTATIONS.PREFERENCES_RESET);

    /**
     * Clear any browser session data
     */
    removeSiteActionModalSessionFlags();
}