﻿import { store } from '@store';
import { IDENTITY } from '@store/getters';
import { SITEMODE } from '@config/constants';
import router from '../router';
import { ERROR_ROUTES } from '../router/routes/error';
import { getJwt } from '@core-portal/api/identity.api';
import { sitebaseHelper } from '@scripts/sitebase-helper';

const SITEBASEROUTES = {
    PORTAL: '/portal',
    INTRANET: '/intranet'
};

/**
 * Get the name of the site based on the site mode
 */
export function getSiteName() {
    let siteMode = store.getters[IDENTITY.siteMode];
    if (siteMode === SITEMODE.INTRANET) {
        return 'Staff Intranet';
    }
    if (siteMode === SITEMODE.BOTH) {
        return 'Intranet/Portal';
    }
    return 'Portal';
}

/**
 * Get the Site Mode value based on the path
 */
export function getSiteModeFromPath() {
    // look at the full route path to work out which site to use
    if (window.location.pathname.startsWith(SITEBASEROUTES.INTRANET)) {
        return SITEMODE.INTRANET;
    }
    return SITEMODE.PORTAL;
}

/**
 * Get the url for this page, but for the other deploy route
 * @param {any} siteMode The site mode to return the updated url for
 */
export function getRedirectPathForSiteMode(siteMode) {

    let fullPath = window.location.pathname;

    // add the search terms
    if (window.location.search) {
        fullPath += window.location.search;
    }
    if (window.location.hash) {
        fullPath += window.location.hash;
    }

    let oldSiteBase = siteMode === SITEMODE.INTRANET ? SITEBASEROUTES.PORTAL : SITEBASEROUTES.INTRANET;
    let newSiteBase = siteMode === SITEMODE.INTRANET ? SITEBASEROUTES.INTRANET : SITEBASEROUTES.PORTAL;
    let siteBaseUrlRegex = new RegExp('^' + oldSiteBase + '/', 'i');
    if (fullPath.match(siteBaseUrlRegex)) {
        fullPath = fullPath.replace(siteBaseUrlRegex, newSiteBase + '/');
    } else if (fullPath === oldSiteBase) {
        fullPath = newSiteBase + '/';
    }

    return fullPath;
}

/**
 * Get if the site is in the "deployment" root (i.e. actual site location as it would be in live)
 * @returns {Boolean}
 */
export function isDeployRoot() {
    return sitebaseHelper.isDeployRoot;
}

/**
 * Set the title of the page/document (in the browser tab)
 * @param {String} title
 */
export function setPageTitle(title) {
    document.title = title;
    setPageTitleSiteMode();
}

/**
 * Set the site mode (e.g. 'Portal') on the document title
 */
export function setPageTitleSiteMode() {

    // Add the site mode/type name to the document title
    let mode = '| Portal'; // default
    let siteMode = store.getters[IDENTITY.siteMode];
    if (siteMode) {
        if (siteMode === SITEMODE.INTRANET || siteMode === SITEMODE.BOTH) {
            mode = '| Staff Intranet';
        }
    }

    // only add the mode if it isn't on there already
    if (!document.title.endsWith(mode)) {
        document.title += ' ' + mode;
    }
}

/**
 * Handle the error response based on the error details
 * @param {Object} error The error received
 * @param {Number} error.code The HTTP status code of the error
 * @param {String} url The route parameter to display if error code is 404/notfound
 */
export function handleApiError(error, url = '/notfound') {
    const { code } = error;

    switch (code) {
    case 401:
        if (!store.getters[IDENTITY.authenticated]) {
            // login
            getJwt();
            break;
        }
        router.replace({ name: ERROR_ROUTES.UNAUTHORISED });
        break;
    case 404:
        router.replace({ name: ERROR_ROUTES.NOT_FOUND, params: { '0': url } });
        break;
    case 400:
        router.replace({ name: ERROR_ROUTES.BAD_REQUEST });
        break;
    case 502: // Bad Gateway
        // stay on the page for a "service unavailable" message
        break;
    case 500: // Internal Server Error
    default:
        router.replace({ name: ERROR_ROUTES.ERROR });
    }
}

/**
 * Helper to extract value from a settled promise
 * using the Promise.allSettled function
 * @param {Object} result
 * @param {string} result.status
 * @param {any} result.value
 * @param {any} fallback The result to return on error
 * @returns {any?} The value of the result if successful, null otherwise
 */
export function getPromiseSettledValue(result, fallback = null) {
    if (result.status === 'fulfilled') {
        return result.value;
    }

    // eslint-disable-next-line
    console.log('problem resolving promise', { result });
    return fallback;
}

/**
 * Wrapper for the Promise.allSettled method
 * to support IE 🙄
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
 * @param {Promise[]} promises
 * @returns {Promise}
 */
export function promiseAllSettled(promises) {
    if (!Promise.allSettled) {
        return Promise.all(promises.map(promise => 
            promise
                .then(value => ({ status: 'fulfilled', value }))
                .catch(reason => ({ status: 'rejected', reason }))
        ));
    }

    return Promise.allSettled(promises);
}

/**
 * Determine if the browser supports
 * webp images or not. Generates a raw
 * webp image of 1x1px as a test.
 * @returns {Promise<Boolean>}
 */
export function supportsWebp() {
    return new Promise(resolve => {
        const image = new Image();
        image.onerror = () => resolve(false);
        image.onload = () => resolve(image.width === 1);
        image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
    }).catch(() => Promise.resolve(false));
}