﻿/**
 * Capitalise the first letter of a piece of text
 * @param {any} text
 */
export function toTitleCase(text) {
    let startingLetter = text.charAt(0).toUpperCase();
    return startingLetter + text.slice(1);
}

/**
 * Characters used for generating random strings.
 * Saved here for performance reasons
 * @type {String[]} 
 */
const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

/**
 * Numbers used for generating random strings
 * @type {String[]}
 */
const nums = '0123456789'.split('');

/**
 * Generate a random string of characters of certain length
 * @param {Number} length How long the returned string should be
 * @param {Boolean} [includeNumeric=false]
 * @returns {String}
 */
export function randomString(length = -1, includeNumeric = false) {
    // decide which characters to use
    const dataset = includeNumeric
        ? [...chars, ...nums]
        : chars;

    // confirm the length of the returned string
    length = length
        ? length
        : Math.floor(Math.random() * dataset.length);

    let str = '';
    for (let i = 0; i < length; i++) {
        str += dataset[Math.floor(Math.random() * dataset.length)];
    }
    return str;
}

/**
 * Truncate a string
 * @param {string} text - any string of text that needs truncating
 * @param {number} the number of characters to return - if ommited then 'text' will be returned
 */
export function truncateString(text, length = 0) {
    if (!text || !length) {
        return text;
    }

    return length < text.length ? text.substring(0, length) + '...' : text;
}