﻿import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist';

//modules
import identity from './modules/identity.module';
import preferences from './modules/preferences.module';


const vuexLocalStorage = new VuexPersist({
    key: 'vuex-dsp',
    storage: window.sessionStorage
});

const modules = {
    identity,
    preferences,
};

export const store = createStore({
    state: {},
    plugins: [
        vuexLocalStorage.plugin
    ],
    modules
});